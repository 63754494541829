import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import MainRouter from "./router/MainRouter";
import { persistor, store } from "./store";
import "./assets/css/style.css";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <MainRouter />
            </Router>
        </PersistGate>
    </Provider>
);
