import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { isAuthenticated } from "../utils/auth";
import { ThreeDots } from "react-loader-spinner";
import TextField from "@mui/material/TextField";
import { moduleConfig } from "../config";
import { CONFIG_VARS } from "../config/globleConfig";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import DynamicSnackBar from "./SnackBar";
import { getAgentDTO, getAgentStatus } from "../utils";

const LoginForm = () => {
    const [fields, setFields] = useState(moduleConfig.loginusers);
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [validationErrors, setValidationErrors] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [snackbarData, setSnackbarData] = useState({ open: false, msg: "", type: "" });
    const history = useHistory(); // Initialize the useHistory hook

    useEffect(() => {
        const pushToLogin = localStorage.getItem("pushed_to_login");
        if (pushToLogin === "true" && pushToLogin) {
            setSnackbarData({ open: true, msg: "You have been logged out!", type: "warning" });
            setTimeout(() => {
                localStorage.removeItem("pushed_to_login");
                setSnackbarData({ open: false, msg: "", type: "" });
            }, 4000);
        }
    }, [formData]);

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const handleChange = (e, schema) => {
        const { name, value } = e.target;

        setFormData((formData) => ({
            ...formData,
            [name]: value,
        }));
        setError("");
        schema
            .validate(value)
            .then(() => setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" })))
            .catch((error) => setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: error.message })));
    };

    const hasValidationErrors = () => {
        for (const fieldName in validationErrors) {
            if (validationErrors.hasOwnProperty(fieldName) && validationErrors[fieldName] !== "") {
                return true;
            }
        }
        return false;
    };

    const buttonDisabled = () => {
        let flag = false;
        for (let i = 0; i < fields.length; i++) {
            const fieldName = fields[i].name;
            const isMandatory = fields[i].mandatory;
            if (isMandatory && !formData[fieldName]) {
                flag = true; // Set flag to true if any mandatory field is not filled
                break; // If any mandatory field is not filled, disable the button
            }
            if (isMandatory && hasValidationErrors()) {
                flag = true;
                break;
            }
        }
        return flag;
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const loginUrl = `${CONFIG_VARS.baseUrl}/user/agents/login`;
        const headers = {
            accept: "application/json",
            "product-id": "1000",
            "tenant-id": "1",
            "Content-Type": "application/json",
            platform: "web",
        };

        const requestBody = {
            admission_number: formData.admission_number,
            password: formData.password,
            isd_code: "+91",
        };

        try {
            const agentStatus = await getAgentStatus("", { admission_number: formData.admission_number });
            if (agentStatus?.active) {
                const response = await axios.post(loginUrl, requestBody, {
                    headers: headers,
                });

                if (response.status === 200) {
                    // Store the access token in local storage
                    localStorage.setItem("accessToken", response.data.accessToken);
                    localStorage.setItem("user_data", JSON.stringify(response.data));
                    await getUserDto(response.data.accessToken);
                    // Redirect to the dashboard page
                    history.push("/dashboard");
                } else {
                    setIsLoading(false);
                }
            } else {
                setSnackbarData({ open: true, msg: "You have been Deactivated!", type: "error" });
                setIsLoading(false);
                setTimeout(() => {
                    setSnackbarData({ open: false, msg: "", type: "" });
                }, 4000);
            }
        } catch (error) {
            setError(error?.response?.data?.detail);
            setIsLoading(false);
        }
    };

    const getUserDto = async (token) => {
        try {
            const userDTO = await getAgentDTO(token);
            const roleIds = userDTO[0].roles.map((role) => role.roleId);
            const roleNames = userDTO[0].roles.map((role) => role.roleName);
            const roleCodes = userDTO[0].roles.map((role) => role.roleCode);

            const isAdmin = (list) =>
                list.map((role) => role.toLowerCase()).some((keyword) => keyword.includes("admin"));

            const superAdmin = roleIds.includes(5);
            const admin = isAdmin(roleNames);
            const inmobiusUser = roleCodes.some((keyword) => keyword.includes("IMR"));
            const inmobiusSuperAdmin = roleCodes.some((keyword) => keyword.includes("ISA"));
            const learnSuperAdmin = roleCodes.some((keyword) => keyword.includes("LSA"));
            const ILSchoolsSuperAdmin = roleCodes.some((keyword) => keyword.includes("ISSA"));

            if (superAdmin && admin) {
                localStorage.setItem("access", "superAdmin");
            } else if (ILSchoolsSuperAdmin) {
                localStorage.setItem("access", "ILSchoolsSuperAdmin");
            } else if (learnSuperAdmin) {
                localStorage.setItem("access", "learnSuperAdmin");
            } else if (inmobiusSuperAdmin) {
                localStorage.setItem("access", "inmobiusSuperAdmin");
            } else if (superAdmin) {
                localStorage.setItem("access", "superAdmin");
            } else if (admin) {
                localStorage.setItem("access", "admin");
            } else if (inmobiusUser) {
                localStorage.setItem("access", "inmobiusUser");
            } else {
                localStorage.setItem("access", "other");
            }
        } catch (error) {
            setSnackbarData({ open: true, msg: error?.response?.data?.detail, type: "error" });
            console.log(error);
        }
    };

    // Add a useEffect hook to check authentication status on component mount
    useEffect(() => {
        if (isAuthenticated()) {
            // User is already authenticated, redirect to the dashboard
            history.push("/dashboard");
        }
    }, [history]);

    return (
        <div className="login-bg-container">
            <h2 className="login-pg-title">Gateway Admin Dashboard</h2>
            <form className="login-pg-form" onSubmit={handleLogin}>
                <h5 className="login-pg-form-title">Login to Your Account</h5>
                <p className="login-pg-form-caption">Enter your Admission Id & Password to login</p>
                {fields.map((field) => (
                    <div className="login-pg-form-field-box" key={field.name}>
                        <TextField
                            id={`floating${field.name}`}
                            name={field.name}
                            value={formData[field.name] || ""}
                            type={field.type === "password" ? (showPassword ? "text" : "password") : field.type}
                            fullWidth
                            onChange={(e) => handleChange(e, field.schema)}
                            label={field.label}
                            variant="outlined"
                            error={!!validationErrors[field.name]}
                            helperText={validationErrors[field.name]}
                            InputProps={
                                field.type === "password"
                                    ? {
                                          endAdornment: (
                                              <InputAdornment position="end">
                                                  <IconButton onClick={handleTogglePasswordVisibility}>
                                                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                  </IconButton>
                                              </InputAdornment>
                                          ),
                                      }
                                    : null
                            }
                        />
                    </div>
                ))}
                <Link className="login-pg-form-fgt-psw" to={ROUTES.RESET_PASSWORD}>
                    Forgot Password?
                </Link>
                {error === "" ? null : <p className="text-error">{error}</p>}
                <Button
                    variant="contained"
                    disabled={buttonDisabled()}
                    type="submit"
                    sx={{ width: "100%", height: "45px", marginTop: "10px" }}>
                    {isLoading ? <ThreeDots color="#ffffff" height={40} width={40} /> : "Login"}
                </Button>
            </form>
            <DynamicSnackBar {...snackbarData} />
        </div>
    );
};
export default LoginForm;
