import React, { useContext } from "react";
import Button from "@mui/material/Button";
import GroupsIcon from "@mui/icons-material/Groups";
import { CommonApiDataContext } from "../../context/CommonApiDataContext";
import { Link } from "react-router-dom";

const BulkUploadBtn = (props) => {
    const { text, link } = props;
    const { access } = useContext(CommonApiDataContext);

    // const openModel = async () => {
    //     access === "admin" ? handleOpenSnackbar(true, "Access denied!", "error") : setOpen(true);
    // };

    return (
        access !== "other" && (
            <Link to={link}>
                <Button
                    // href="/users-bulk-upload"
                    // variant="contained"
                    startIcon={<GroupsIcon />}
                    sx={{ marginLeft: "10px" }}>
                    {text}
                </Button>
            </Link>
        )
    );
};

export default BulkUploadBtn;
