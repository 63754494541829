export const CONFIG_VARS = Object.freeze({
    //baseUrl: "https://gatewaystaging.devinfinitylearn.in/api/v1", // staging URL
    baseUrl: "https://gatewayapi.infinitylearn.com/api/v1", // Production URL
    // baseUrl: "https://gatewayapicms.infinitylearn.com/api/v1", // Production URL (CMS)
    //baseUrl: "https://gatewayapi.devinfinitylearn.in/api/v1", // Proprod URL
    otpUrl: "",
    countryApiKey: "a1JiWnJrVVp3YmxheTdybjFtbDVId2xjOU1UYUZ4dUZHMVNBMk5pZA==",
    bulkuploadAllowedProds: [300, 500, 700],
    allowedRoleIds: [32],
    inmobiusProdId: 100,
    moduleAccessMap: {
        inmobiusUser: ["Tenants"],
        inmobiusSuperAdmin: ["Tenants", "Schools", "Agents"],
        learnSuperAdmin: ["Tenants", "Users", "Agents"],
        ILSchoolsSuperAdmin: ["Tenants", "Schools", "Users", "Agents"],
        superAdmin: ["Tenants", "Schools", "Grades", "Products", "Roles", "Exams Types", "Programs", "Users", "Agents"],
    },
    schoolsProds: [100, 500],
    learnSuperAdminProds: [7, 300],
    ILSchoolsSuperAdminProds: [200, 500, 700],
});
