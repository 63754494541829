import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { ROUTES } from "./routes";

export const linksData = [
    {
        title: "Dashboard",
        route: ROUTES.CARDS,
        menuIcon: <DashboardOutlinedIcon />,
    },
    {
        title: "Onboarding",
        menuIcon: <MenuOutlinedIcon />,
        links: [
            {
                name: "Tenants",
                route: ROUTES.TENANTS,
            },
            {
                name: "Schools",
                route: ROUTES.SCHOOLS,
            },
            {
                name: "Grades",
                route: ROUTES.GRADES,
            },
            {
                name: "Products",
                route: ROUTES.PRODUCTS,
            },
            {
                name: "Roles",
                route: ROUTES.ROLES,
            },
            {
                name: "Exams Types",
                route: ROUTES.EXAMS,
            },
            {
                name: "Users",
                route: ROUTES.USERS,
            },
            {
                name: "Programs",
                route: ROUTES.PROGRAMS,
            },
            {
                name: "Agents",
                route: ROUTES.AGENTS,
            },
        ],
    },
];
