import { useContext, useEffect, useState } from "react";
import DynamicForm from "../../../components/forms/DynamicForm";
import { moduleConfig } from "../../../config";
import { TableConfig } from "../../table/TableConfig";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";
import { CONFIG_VARS } from "../../../config/globleConfig";

const UserWrapper = (props) => {
    const { onSubmit, closeModal, data, ModalTitle, dta, setData } = props;
    const [fields, setFields] = useState(moduleConfig.users.fields);
    const [formData, setFormData] = useState({ ...data });
    const [loader, setLoader] = useState(false);

    const { products, tenants, grades, handleOpenSnackbar, access } = useContext(CommonApiDataContext);

    useEffect(() => {
        const getAllRolesAndProducts = async () => {
            setLoader(true);
            try {
                // const roles = await GetRoles(1, 100);
                // const products = await getProducts(1, 100);
                // const updatedProducts = products.products?.map((e) => ({ label: e.product_name, value: e.product_id }));

                const filteredTenants = tenants?.filter((e) => e.label === data?.tenant_id);
                const filteredProducts = products?.filter((e) => e.label === data?.product_id);
                const filteredGrades = grades?.filter((e) => e.label === data?.grade);
                data &&
                    setFormData((prev) => ({
                        ...prev,
                        product_id: filteredProducts[0],
                        grade: filteredGrades[0],
                        tenant_id: filteredTenants[0],
                    }));

                const updatedFields = fields.map((e) => {
                    if (e.options && e.name === "grade") {
                        return { ...e, options: grades?.filter((e) => e.active) };
                    } else if (e.options && e.name === "product_id") {
                        return { ...e, options: products };
                    } else if (e.options && e.name === "tenant_id") {
                        return { ...e, options: tenants };
                    }
                    return e;
                });

                const reqFields = data ? updatedFields : updatedFields.filter((e) => e.showInCreate && e);

                setFields(reqFields);
                setLoader(false);
            } catch (error) {
                handleOpenSnackbar(true, error?.response?.data?.detail, "error");
                setLoader(false);
            }
        };

        getAllRolesAndProducts();
    }, []);

    /*@TODO: need remove in final stage */
    const getProds = () => {
        if (access && access.includes("inmobius")) {
            return products.filter((p) => p.value === CONFIG_VARS.inmobiusProdId);
        } else if (access && access === "learnSuperAdmin") {
            return products.filter((p) => CONFIG_VARS.learnSuperAdminProds.includes(Number(p.value)));
        } else if (access && access === "ILSchoolsSuperAdmin") {
            return products.filter((p) => CONFIG_VARS.ILSchoolsSuperAdminProds.includes(Number(p.value)));
        } else {
            return products;
        }
    };

    return (
        <DynamicForm
            fields={fields}
            onSubmit={onSubmit}
            closeModal={closeModal}
            ModalTitle={ModalTitle}
            data={data && formData}
            loader={loader}
            actions={moduleConfig?.users?.actions}
            dta={dta}
            setData={setData}
            navId={TableConfig.tables[6].items[0].id}
        />
    );
};

export default UserWrapper;
