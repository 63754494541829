import axios from "axios";
import { CONFIG_VARS } from "../config/globleConfig";

const headers = {
    accept: "application/json",
    "product-id": 1000,
    "tenant-id": 1,
    platform: "web",
};

const makeRequest = async (method, url, accessToken, data = {}) => {
    try {
        const response = await axios({
            method,
            url,
            headers: {
                ...headers,
                Authorization: `Bearer ${accessToken}`,
            },
            data,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

// ================================================= Tenants APIs Start ================================================================
export const getTenantDataByProducts = async (accessToken, page, limit, productId, sort) =>
    makeRequest(
        "GET",
        `${CONFIG_VARS.baseUrl}/tenant/list?products=${productId}&page=${page}&limit=${limit}&sort=${sort}`,
        accessToken
    );

export const getTenantData = async () =>
    makeRequest(
        "GET",
        `${CONFIG_VARS.baseUrl}/tenant`
        // accessToken
    );

export const createTenant = async (accessToken, formData) =>
    makeRequest("POST", `${CONFIG_VARS.baseUrl}/tenant/create`, accessToken, formData);

export const updateTenant = async (accessToken, formData) =>
    makeRequest("PUT", `${CONFIG_VARS.baseUrl}/tenant`, accessToken, formData);

export const searchTenants = async (accessToken, searchData, page, limit, productId) =>
    makeRequest(
        "POST",
        `${CONFIG_VARS.baseUrl}/tenant/search?page=${page}&limit=${limit}&productId=${productId}`,
        accessToken,
        searchData
    );

// ================================================= Tenants APIs Ends =================================================================

// ================================================= Schools APIs Start ================================================================

export const GetAllSchools = async (accessToken, page, limit, productId) => {
    const getEndPoint = () => {
        switch (Number(productId)) {
            case 500:
                return `${CONFIG_VARS.baseUrl}/ilschool?products=${productId}&page=${page}&limit=${limit}`;
            case 100:
                return `${CONFIG_VARS.baseUrl}/school/list?products=${productId}&page=${page}&limit=${limit}`;
            default:
                return;
        }
    };

    return makeRequest("GET", getEndPoint(), accessToken);
};

export const SearchSchools = async (accessToken, searchData, page, limit, productId) => {
    const getEndPoint = () => {
        switch (Number(productId)) {
            case 500:
                return `${CONFIG_VARS.baseUrl}/ilschool/search?page=${page}&limit=${limit}&productId=${productId}`;
            case 100:
                return `${CONFIG_VARS.baseUrl}/school/search?products=${productId}&page=${page}&limit=${limit}`;
            default:
                return;
        }
    };

    try {
        const response = await axios.post(getEndPoint(), searchData, {
            headers: {
                accept: "application/json",
                "product-id": 1000,
                "tenant-id": Number(productId) === 500 ? 2 : 1,
                Authorization: `Bearer ${accessToken}`,
                platform: "web",
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const CreateSchool = async (accessToken, formData) => {
    const getEndPoint = () => {
        switch (Number(formData.product_id)) {
            case 500:
                return `${CONFIG_VARS.baseUrl}/ilschool`;
            case 100:
                return `${CONFIG_VARS.baseUrl}/school/register`;
            default:
                return;
        }
    };
    try {
        const response = await axios.post(getEndPoint(), formData, {
            headers: {
                accept: "application/json",
                "product-id": 1000,
                "tenant-id": 1,
                Authorization: `Bearer ${accessToken}`,
                platform: "web",
            },
        });
        return response.data?.detail;
    } catch (error) {
        throw error;
    }
    // makeRequest("POST", `${CONFIG_VARS.baseUrl}/school/register`, accessToken, formData);
};

export const UpdateSchool = async (accessToken, formData) => {
    const getEndPoint = () => {
        switch (Number(formData.product_id)) {
            case 500:
                return `${CONFIG_VARS.baseUrl}/ilschool`;
            case 100:
                return `${CONFIG_VARS.baseUrl}/school`;
            default:
                return;
        }
    };
    try {
        const response = await axios.put(getEndPoint(), formData, {
            headers: {
                accept: "application/json",
                "product-id": 1000,
                "tenant-id": 1,
                Authorization: `Bearer ${accessToken}`,
                platform: "web",
            },
        });
        if (Number(formData.product_id) === 500) {
            return response.data?.detail;
        } else {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
    // return makeRequest("PUT", getEndPoint(), accessToken, formData);
};

// export const SearchSchools = async (accessToken, searchData, page, limit, productId) => {
//     const getEndPoint = () => {
//         switch (Number(productId)) {
//             case 500:
//                 return `${CONFIG_VARS.baseUrl}/ilschool/search?page=${page}&limit=${limit}&productId=${productId}`;
//             case 100:
//                 return `${CONFIG_VARS.baseUrl}/school/search?products=${productId}&page=${page}&limit=${limit}`;
//             default:
//                 return;
//         }
//     };

//     try {
//         const response = await axios.put(getEndPoint(), searchData, {
//             headers: {
//                 accept: "application/json",
//                 "product-id": 1000,
//                 "tenant-id": 1,
//                 Authorization: `Bearer ${accessToken}`,
//                 platform: "web",
//             },
//         });

//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

// ================================================= Schools APIs End =================================================================

// ================================================= Grades APIs Start ================================================================
export const getGradesDataByProducts = async (accessToken, page, limit, productId) =>
    makeRequest(
        "GET",
        `${CONFIG_VARS.baseUrl}/grade/list?productId=${productId}&page=${page}&limit=${limit}`,
        accessToken
    );

export const getGrades = async (accessToken) => makeRequest("GET", `${CONFIG_VARS.baseUrl}/grade/list`, accessToken);

export const createGrades = async (accessToken, formData) =>
    makeRequest("POST", `${CONFIG_VARS.baseUrl}/grade/create`, accessToken, formData);

export const updateGrades = async (accessToken, formData) =>
    makeRequest("PUT", `${CONFIG_VARS.baseUrl}/grade`, accessToken, formData);

// ================================================= Grades APIs Ends =================================================================

// ================================================= Products APIs Start ==============================================================

export const getProducts = async (page, limit) =>
    makeRequest("GET", `${CONFIG_VARS.baseUrl}/product?page=${page}&limit=${limit}`);

export const CreateProduct = async (accessToken, formData) =>
    makeRequest("POST", `${CONFIG_VARS.baseUrl}/product`, accessToken, formData);

export const UpdateProduct = async (accessToken, formData) =>
    makeRequest("PUT", `${CONFIG_VARS.baseUrl}/product`, accessToken, formData);

// ================================================= Products APIs Ends ===============================================================

// ================================================= Roles APIs Start =================================================================

export const GetRoles = async (page, limit) =>
    makeRequest("GET", `${CONFIG_VARS.baseUrl}/role?page=${page}&limit=${limit}`);

export const CreateRole = async (accessToken, formData) =>
    makeRequest("POST", `${CONFIG_VARS.baseUrl}/role`, accessToken, formData);

export const UpdateRole = async (accessToken, formData) =>
    makeRequest("PUT", `${CONFIG_VARS.baseUrl}/role`, accessToken, formData);

// ================================================= Roles APIs Ends =================================================================

// ================================================= Exams APIs Start ================================================================

export const getExams = async (accessToken, page, limit) =>
    makeRequest("GET", `${CONFIG_VARS.baseUrl}/exam?page=${page}&limit=${limit}`, accessToken);

export const CreateExam = async (accessToken, formData) =>
    makeRequest("POST", `${CONFIG_VARS.baseUrl}/exam`, accessToken, formData);

export const UpdateExam = async (accessToken, formData) =>
    makeRequest("PUT", `${CONFIG_VARS.baseUrl}/exam`, accessToken, formData);

// ================================================= Exams APIs Ends =================================================================

// ================================================= Programs APIs Start =============================================================

export const getPrograms = async (accessToken, page, limit, productId) =>
    makeRequest(
        "GET",
        `${CONFIG_VARS.baseUrl}/program?productId=${productId}&page=${page}&limit=${limit}`,
        accessToken
    );

export const CreateProgram = async (accessToken, formData) =>
    makeRequest("POST", `${CONFIG_VARS.baseUrl}/program`, accessToken, formData);

export const UpdateProgram = async (accessToken, formData) =>
    makeRequest("PUT", `${CONFIG_VARS.baseUrl}/program`, accessToken, formData);

// ================================================= Programs APIs Ends ================================================================

// ================================================= Agents APIs Start =================================================================

export const getAgents = async (accessToken, page, limit, productId) =>
    makeRequest(
        "GET",
        `${CONFIG_VARS.baseUrl}/user/agents?page=${page}&limit=${limit}&products=${productId}`,
        accessToken
    );

export const searchAgents = async (accessToken, payload) =>
    makeRequest("POST", `${CONFIG_VARS.baseUrl}/user/agents/search`, accessToken, payload);

export const getAgentDTO = async (accessToken) =>
    makeRequest("GET", `${CONFIG_VARS.baseUrl}/user/agents/dto`, accessToken);

export const getAgent = async (accessToken, admissionId) =>
    makeRequest("GET", `${CONFIG_VARS.baseUrl}/user/agents/${admissionId}`, accessToken);

export const CreateAgents = async (accessToken, formData) => {
    const headersWithTenantId = {
        ...headers,
        "tenant-id": formData.products.includes(200) ? 2 : 1,
        // Number(formData.product_id) === 200 ? 2 : 1
        Authorization: `Bearer ${accessToken}`,
    };

    try {
        const response = await axios.post(`${CONFIG_VARS.baseUrl}/user/agents/register`, formData, {
            headers: headersWithTenantId,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const UpdateAgent = async (accessToken, formData) =>
    makeRequest("PUT", `${CONFIG_VARS.baseUrl}/user/agents/update`, accessToken, formData);

export const ActivateAgents = async (accessToken, formData) =>
    makeRequest("POST", `${CONFIG_VARS.baseUrl}/user/agents/activate`, accessToken, formData);

export const DeactivateAgents = async (accessToken, formData) =>
    makeRequest("POST", `${CONFIG_VARS.baseUrl}/user/agents/deactivate`, accessToken, formData);

export const getAgentStatus = async (accessToken, formData) =>
    makeRequest("POST", `${CONFIG_VARS.baseUrl}/user/agents/verify`, accessToken, formData);

// ================================================= Agents APIs Ends ================================================================

export const getCountries = async () => makeRequest("GET", `${CONFIG_VARS.baseUrl}/state-city/countries`);

export const getStates = async (countryId) =>
    makeRequest("GET", `${CONFIG_VARS.baseUrl}/state-city/states?country_id=${countryId}`);

export const getCities = async (countryId, stateId) =>
    makeRequest("GET", `${CONFIG_VARS.baseUrl}/state-city/cities?country_id=${countryId}&state_id=${stateId}`);

// ================================================= Users APIs Starts ===============================================================

export const getUsers = async (accessToken, page, limit, productId, serachPeram) =>
    makeRequest(
        "GET",
        `${CONFIG_VARS.baseUrl}/user/list?page=${page}&limit=${limit}&productIdFilter=${productId}&search=${serachPeram}`,
        accessToken
    );
export const registerUser = async (accessToken, formData) =>
    makeRequest("POST", `${CONFIG_VARS.baseUrl}/user/register`, accessToken, formData);

export const updateUser = async (accessToken, formData) => {
    console.log(formData, "sdkfj");
    const headers = {
        accept: "application/json",
        "product-id": Number(formData?.product_id),
        "tenant-id": Number(formData?.tenant_id),
        Authorization: `Bearer ${accessToken}`,
        platform: "web",
    };
    try {
        const response = await axios.put(`${CONFIG_VARS.baseUrl}/user/meta/update`, formData, {
            headers: headers,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const UsersBulkUpload = async (accessToken, file, productId) => {
    try {
        const response = await axios.post(`${CONFIG_VARS.baseUrl}/user/bulk-upload?productId=${productId}`, file, {
            headers: {
                accept: "application/json",
                "product-id": productId,
                "tenant-id": Number(productId) === 700 ? 6 : Number(productId) === 500 ? 4 : 1,
                Authorization: `Bearer ${accessToken}`,
                platform: "web",
                "Content-Type": "multipart/form-data",
            },
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const ValidateBulkUploadFile = async (accessToken, file, prodId) => {
    try {
        const response = await axios.post(
            `${CONFIG_VARS.baseUrl}/user/bulk-upload/validate?productId=${prodId}`,
            file,
            {
                headers: {
                    accept: "application/json",
                    "product-id": prodId,
                    "tenant-id": Number(prodId) === 700 ? 6 : Number(prodId) === 500 ? 4 : 1,
                    Authorization: `Bearer ${accessToken}`,
                    platform: "web",
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getSingleJobDetails = async (jobId) => {
    try {
        const response = await axios.post(
            `${CONFIG_VARS.baseUrl}/user/bulk-upload/jobs/${jobId}`,
            {},
            {
                headers: {
                    accept: "application/json",
                    "product-id": 500,
                    "tenant-id": 60,
                    platform: "web",
                },
            }
        );
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getAllJobDetails = async (page, limit, productId) => {
    try {
        const response = await axios.post(
            `${CONFIG_VARS.baseUrl}/user/bulk-upload/jobs?page=${page}&limit=${limit}`,
            {},
            {
                headers: {
                    accept: "application/json",
                    "product-id": productId,
                    "tenant-id": Number(productId) === 500 ? 60 : 1,
                    platform: "web",
                },
            }
        );
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getTenantOptions = (payload) => {
    const options = [
        { label: "tenant 1", value: 0 },
        { label: "tenant 2", value: 1 },
        { label: "tenant 3", value: 2 },
    ];
    return options;
};

export const getBranchOptions = (payload) => {
    const branchIds = [
        { label: "branch 1", value: 3 },
        { label: "branch 2", value: 4 },
        { label: "branch 3", value: 5 },
    ];
    return branchIds;
};

export const getSchoolIds = (payload) => {
    const schoolIds = [
        { label: "school 1", value: 6 },
        { label: "school 2", value: 7 },
        { label: "school 3", value: 8 },
    ];
    return schoolIds;
};
// ================================================= Users APIs ends =================================================================

// ================================================= API for Upload File =============================================================

export const uploadImage = async (accessToken, file) => {
    try {
        const response = await axios.post(`${CONFIG_VARS.baseUrl}/mdm/imageUpload`, file, {
            headers: {
                accept: "application/json",
                "product-id": 1000,
                "tenant-id": 1,
                Authorization: `Bearer ${accessToken}`,
                platform: "web",
                "Content-Type": "multipart/form-data",
            },
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

// ================================================= API to get Dashboard counts =======================================================

export const getDashboardCount = async (accessToken) =>
    makeRequest("GET", `${CONFIG_VARS.baseUrl}/mdm/stats`, accessToken);
