import { useContext, useEffect, useState } from "react";
import DynamicForm from "../../../components/forms/DynamicForm";
import { moduleConfig } from "../../../config";
// import { getCountries, getTenantData, getStates, getCities, getProducts } from "../../../utils";
import { TableConfig } from "../../table/TableConfig";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";
import { CONFIG_VARS } from "../../../config/globleConfig";
import { getCities, getCountries, getStates } from "../../../utils";

const SchoolsWrapper = (props) => {
    const { onSubmit, closeModal, data, ModalTitle, dta, setData } = props;
    const [fields, setFields] = useState(moduleConfig.schools.fields);
    const [formData, setFormData] = useState({ ...data });
    const [loader, setLoader] = useState(false);
    const [obj, setobj] = useState({ countryId: null, stateId: null, cityId: null });

    const { products, tenants, access, handleOpenSnackbar } = useContext(CommonApiDataContext);

    useEffect(() => {
        const getAllSelectData = async () => {
            setLoader(true);
            try {
                const countries = await getCountries();

                const updatedCountries = countries.map((e) => ({ label: e.country_name, value: e.country_id }));

                // const filteredCountries = updatedCountries?.filter((country) =>
                //     data?.countries.includes(country.value)
                // );

                // const filteredTenants = updatedTenants?.filter((tenant) => tenant?.tenant_id === data?.tenant_id);
                // const filteredProducts = updatedProducts?.filter((product) => product?.product_id === data?.product_id);
                const filteredTenants = tenants?.filter((tenant) => tenant?.label === data?.tenant_id)[0];
                const filteredProducts = products?.filter((product) => product?.label === data?.product_id)[0];

                // const [states, cities] = await Promise.all([getStates("IN"), getCities("IN", data?.city)]);

                // const updatedStates = states.map((e) => ({ label: e.state_name, value: e.state_id }));
                // const updatedCities = cities.map((e) => ({ label: e.city_name, value: e.city_id }));

                data &&
                    setFormData((prev) => ({
                        ...prev,
                        product_id: filteredProducts,
                        tenant_id: filteredTenants,
                        // country_id: { label: data?.country_name, value: data?.country_id },
                        // state_id: { label: data?.state_name, value: data?.state_id },
                        // city_id: { label: data?.city_name, value: data?.city_id },
                        state_id: data?.state_name,
                        city_id: data?.city_name,
                    }));
                const updatedFields = fields.map((e) => {
                    if (e.options && e.name === "product_id") {
                        return { ...e, options: getProds()?.filter((e) => e.active) };
                    } else if (e.options && e.name === "tenant_id") {
                        return { ...e, options: tenants?.filter((e) => e?.active) };
                    } else if (e.options && e.name === "country_id") {
                        return { ...e, options: updatedCountries };
                    }
                    return e;
                });
                const reqFields = data ? updatedFields : updatedFields.filter((e) => e.showInCreate && e);
                setFields(reqFields);
                setLoader(false);
            } catch (error) {
                handleOpenSnackbar(true, error?.response?.data?.detail || error.message, "error");
                setLoader(false);
            }
        };

        getAllSelectData();
    }, []);

    /*@TODO: need remove in final stage */
    const getProds = () => {
        if (access && access.includes("inmobius")) {
            return products.filter((p) => p.value === CONFIG_VARS.inmobiusProdId);
        } else {
            return products.filter((p) => CONFIG_VARS.schoolsProds.includes(p.value));
        }
    };

    useEffect(() => {
        const getStates1 = async (countryId) => {
            const states = await getStates(countryId);
            const updatedStates = states.map((e) => ({ label: e.state_name, value: e.state_id }));
            const updatedFields = fields.map((e) => (e.name === "state_id" ? { ...e, options: updatedStates } : e));
            setFields(updatedFields);
        };
        obj?.countryId && getStates1(obj?.countryId);
    }, [obj?.countryId]);

    useEffect(() => {
        const getCities1 = async (countryId, stateId) => {
            try {
                const cities = await getCities(countryId, stateId);
                const updatedCities = cities.map((e) => ({ label: e.city_name, value: e.city_id }));
                const updatedFields = fields.map((e) => (e.name === "city_id" ? { ...e, options: updatedCities } : e));
                setFields(updatedFields);
            } catch (error) {
                console.error("Error fetching cities:", error);
            }
        };
        obj?.countryId && obj?.stateId && getCities1(obj?.countryId, obj?.stateId);
    }, [obj?.countryId && obj?.stateId]);

    return (
        <DynamicForm
            fields={fields}
            onSubmit={onSubmit}
            closeModal={closeModal}
            ModalTitle={ModalTitle}
            data={data && formData}
            loader={loader}
            actions={moduleConfig?.schools?.actions}
            dta={dta}
            setData={setData}
            navId={TableConfig.tables[1].items[0].id}
            setobj={setobj}
        />
    );
};

export default SchoolsWrapper;
