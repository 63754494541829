import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Table from "../../table/Table";
import { getTenantDataByProducts, searchTenants, updateTenant } from "../../../utils";
import { isAuthenticated } from "../../../utils/auth";
import TenantsWrapper from "./TenantsWrapper";
import { CreateBtn } from "../../../components";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";
import ModalDialog from "../../../components/modal/modalDialog";
import { CONFIG_VARS } from "../../../config/globleConfig";
import { moduleConfig } from "../../../config";
import { GetSearchParams } from "../../../utils/reusedMethods";
const options = [
    { label: "Inmobius", value: "IM" },
    { label: "Infinity Learn", value: "IL" },
];
const Tenants = () => {
    const [data, setData] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(20);
    const [loading, setLoading] = useState(true);
    const [productId, setProductId] = useState(null);
    const [open, setOpen] = useState(false);
    const [sort, setSort] = useState("desc");
    const [search, setSearch] = useState({ searchText: "", searchBy: moduleConfig.tenants?.searchableItems[0]?.value });

    const { products, grades, handleOpenSnackbar, setKeys, access } = useContext(CommonApiDataContext);

    const history = useHistory();
    const searchParams = GetSearchParams();

    const getAccessToken = () => localStorage.getItem("accessToken");

    useEffect(() => {
        document.title = "Gateway Admin | Tenants";
        setKeys({ prods: "products", grads: "grades", src: "source" });
    }, [setKeys]);

    useEffect(() => {
        if (!isAuthenticated()) {
            history.push("/login");
        }
    }, [history]);

    // useEffect(() => {
    //     setProductId((products && products[0]?.value) || null);
    // }, [products]);

    /*@TODO: need remove in final stage */
    useEffect(() => {
        /*@TODO: need remove in final stage */
        const getProds = () => {
            if (access && access.includes("inmobius")) {
                return products.filter((p) => p.value === CONFIG_VARS.inmobiusProdId);
            } else if (access && access === "learnSuperAdmin") {
                return products.filter((p) => CONFIG_VARS.learnSuperAdminProds.includes(Number(p.value)));
            } else if (access && access === "ILSchoolsSuperAdmin") {
                return products.filter((p) => CONFIG_VARS.ILSchoolsSuperAdminProds.includes(Number(p.value)));
            } else {
                return products;
            }
        };

        const inmobiusProd = getProds();
        const product_id = searchParams.get("product_id");
        product_id ? setProductId(product_id) : setProductId((inmobiusProd && inmobiusProd[0]?.value) || "");
    }, [access, products, searchParams]);

    // get Tenants Data whenever Product Id, page, limit and sort changes
    useEffect(() => {
        const accessToken = getAccessToken();
        const fetchData = async () => {
            setLoading(true);
            try {
                const tenantsData = await getTenantDataByProducts(accessToken, currPage, limit, productId, sort);
                const updatedData = tenantsData?.tenants?.map((item) => ReplaceIdsWithLabels(item));
                setData(updatedData);
                setCurrPage(tenantsData?.currentPage);
                setTotalPages(tenantsData?.totalPages);
                setLoading(false);
            } catch (error) {
                handleOpenSnackbar(true, error?.response?.data?.detail, "error");
                setLoading(false);
            }
        };
        productId && (search.searchText === "" ? fetchData() : onSearchTenants());
    }, [currPage, limit, productId, sort]);

    const ReplaceIdsWithLabels = (item) => {
        const UP = item?.products?.map((prodId) => {
            const product = products?.find((prod) => prod?.value === prodId);
            return product ? product?.label : prodId;
        });

        const UG = item?.grades?.map((gradeId) => {
            const grade = grades?.find((itemData) => itemData?.value === gradeId);

            return grade ? grade?.label : gradeId;
        });

        const US = options?.filter((source) => source.value === item?.source)[0]?.label;

        return { ...item, products: UP, grades: UG, source: US };
    };

    const closeModal = () => setOpen(false);

    const updateTenantData = async (updatedData) => {
        const accessToken = getAccessToken();
        try {
            const updatedTenantData = await updateTenant(accessToken, updatedData);
            const UUD = data.map((e) => {
                if (e.tenant_id === updatedTenantData.tenant_id) {
                    return ReplaceIdsWithLabels(updatedTenantData);
                }
                return e;
            });
            setData(UUD);
            handleOpenSnackbar(true, "Tenant has been successfully Updated", "success");
        } catch (error) {
            handleOpenSnackbar(true, error?.response?.data?.detail, "error");
        }
    };

    const handleToggle = async (item) => {
        let chngIsAtvTo = null;

        const updatedList = data.map((e) => {
            if (e?.tenant_id === item?.tenant_id) {
                const isActive = item?.is_active;
                chngIsAtvTo = !isActive;
                return {
                    ...e,
                    is_active: !isActive,
                };
            }
            return e;
        });

        // payload for activate or deactivate the Tenants
        const US = options?.filter((source) => source.label === item?.source)[0]?.value;
        const updateStatusPayload = {
            tenant_id: item?.tenant_id,
            is_active: chngIsAtvTo,
            source: US,
        };
        await updateTenantData(updateStatusPayload);
    };

    const onSearchTenants = async () => {
        const searchObj = {
            search_text: search.searchText,
            wild_search: true,
            in_phone: search.searchBy.includes("phone"),
            in_code: search.searchBy.includes("code"),
            in_contact_name: search.searchBy.includes("contact_name"),
            in_name: search.searchBy.includes("name"),
            in_email: search.searchBy.includes("email"),
            source: parseInt(productId) === 100 ? "IM" : "IL",
        };
        setLoading(true);
        try {
            const searchedData = await searchTenants(getAccessToken(), searchObj, currPage, limit, productId);
            const updatedData = searchedData?.tenants?.map((item) => ReplaceIdsWithLabels(item));
            setData(updatedData);
            setCurrPage(searchedData?.currentPage);
            setTotalPages(searchedData?.totalPages);
            setLoading(false);
        } catch (error) {
            handleOpenSnackbar(true, error?.response?.data?.detail, "error");
            setLoading(false);
        }
    };

    return (
        <section className="section">
            <div className="title-createbtn-box">
                <h1 className="title">Tenants</h1>
                <CreateBtn setOpen={setOpen} />
                <ModalDialog open={open} onClose={() => setOpen(false)}>
                    <TenantsWrapper ModalTitle="Create Tenant" closeModal={closeModal} dta={data} setData={setData} />
                </ModalDialog>
            </div>
            <Table
                navigationId={0}
                pageTitle="Tenants"
                data={data}
                loading={loading}
                setCurrPage={setCurrPage}
                setTotalPages={setTotalPages}
                currPage={currPage}
                totalPages={totalPages}
                limit={limit}
                setLimit={setLimit}
                onToggle={handleToggle}
                updateItem={updateTenantData}
                EditForm={TenantsWrapper}
                setProductIds={setProductId}
                setData={setData}
                setSort={setSort}
                sort={sort}
                setSearch={setSearch}
                search={search}
                onSearch={onSearchTenants}
            />
        </section>
    );
};

export default Tenants;
