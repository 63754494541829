import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UpdateProduct, getProducts } from "../../../utils";
import { isAuthenticated } from "../../../utils/auth";
import Table from "../../table/Table";
import ProductsWrapper from "./ProductsWrapper";
import { CreateBtn } from "../../../components";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";
import ModalDialog from "../../../components/modal/modalDialog";
import { moduleConfig } from "../../../config";

const Products = () => {
    const [data, setData] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(20);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState({
        searchText: "",
        searchBy: moduleConfig.products?.searchableItems[0]?.value,
    });

    const history = useHistory();

    // Check if the user is authenticated on component mount
    useEffect(() => {
        document.title = "Gateway Admin | Products";
        if (!isAuthenticated()) {
            // User is not authenticated, redirect to the login page
            history.push("/login");
        }
    }, [history]);

    const closeModal = () => setOpen(false);

    const { handleOpenSnackbar } = useContext(CommonApiDataContext);

    const getAccessToken = () => {
        // Retrieve the access token from local storage
        return localStorage.getItem("accessToken");
    };

    useEffect(() => {
        const getAllProducts = async () => {
            setLoading(true);
            try {
                const data = await getProducts(currPage, limit);
                setData(data?.products?.reverse());
                setCurrPage(data?.currentPage);
                setTotalPages(data?.totalPages);
                setLoading(false);
            } catch (error) {
                handleOpenSnackbar(true, error?.response?.data?.detail, "error");
                setLoading(false);
            }
        };
        getAllProducts();
    }, [currPage, handleOpenSnackbar, limit]);

    const onUpdateProduct = async (formData) => {
        const accessToken = getAccessToken();

        try {
            const res = await UpdateProduct(accessToken, formData);
            const UUD = data.map((e) => {
                if (e.product_id === formData.product_id) {
                    return res;
                }
                return e;
            });
            setData(UUD);
            console.log(res);
            handleOpenSnackbar(true, "Product has been successfully Updated", "success");
        } catch (error) {
            handleOpenSnackbar(true, error.message, "error");
        }
    };

    const handleToggle = async (item) => {
        let chngIsAtvTo = null;
        const updatedList = data.map((e) => {
            if (e?.product_id === item?.product_id) {
                const isActive = item?.is_active;
                chngIsAtvTo = !isActive;
                return {
                    ...e,
                    is_active: !isActive,
                };
            }
            return e;
        });

        // payload for activate or deactivate the Products
        const updateStatusPayload = {
            product_id: item?.product_id,
            is_active: chngIsAtvTo,
        };
        await onUpdateProduct(updateStatusPayload);
    };

    return (
        <section className="section">
            <div className="title-createbtn-box">
                <h1 className="title">Products</h1>
                <CreateBtn setOpen={setOpen} />
                <ModalDialog open={open} onClose={() => setOpen(false)}>
                    <ProductsWrapper ModalTitle="Create Product" closeModal={closeModal} dta={data} setData={setData} />
                </ModalDialog>
            </div>
            <Table
                navigationId={3}
                pageTitle="Products"
                selectProducts={false}
                data={data}
                setCurrPage={setCurrPage}
                setTotalPages={setTotalPages}
                currPage={currPage}
                totalPages={totalPages}
                limit={limit}
                setLimit={setLimit}
                onToggle={handleToggle}
                loading={loading}
                updateItem={onUpdateProduct}
                EditForm={ProductsWrapper}
                setData={setData}
                setSearch={setSearch}
                search={search}
            />
        </section>
    );
};

export default Products;
