import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG_VARS } from "../../constants/configuration";

const { baseUrl, headers, apiVersion } = CONFIG_VARS;

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    headers,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth?.tokens?.access_token;
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    }
  }),
  endpoints: (build) => ({

    resetPassword: build.mutation({
      query: (body) => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/reset-password/user`,
        method: "POST",
        body: body
      })
    }),
    forgotPassword: build.mutation({
      query: (body) => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/forget-password/user`,
        method: "POST",
        body: body
      })
    })
  })
});

export const {
  useResetPasswordMutation,
  useForgotPasswordMutation
} = authApi;
