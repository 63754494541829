import {
    codeSchema,
    multiSelectSchema,
    nameSchema,
    phoneSchema,
    passwordSchema,
    emailSchema,
    noSpecCharSchema,
    productIdSchema,
    URLSchema,
    nameSpaceSchema,
    singleselectSchema,
} from "../components/validations/validations";
import { radioGroupDirectionStylesForRoles } from "../constants";
import {
    getBranchOptions,
    getGrades,
    getSchoolIds,
    getTenantOptions,
    createTenant,
    updateTenant,
    CreateSchool,
    UpdateSchool,
    CreateAgents,
    UpdateAgent,
    createGrades,
    updateGrades,
    CreateProduct,
    UpdateProduct,
    CreateRole,
    UpdateRole,
    CreateExam,
    UpdateExam,
    CreateProgram,
    UpdateProgram,
    updateUser,
} from "../utils";

export const moduleConfig = {
    loginusers: [
        {
            name: "admission_number",
            type: "text",
            label: "Admission Id",
            schema: nameSchema,
            mandatory: true,
        },
        {
            name: "password",
            type: "password",
            label: "Password",
            schema: passwordSchema,
            mandatory: true,
        },
    ],

    tenants: {
        actions: {
            create: createTenant,
            update: updateTenant,
        },
        fields: [
            {
                name: "tenant_id",
                type: "text",
                label: "Tenant Id",
                mandatory: true,
                showInCreate: false,
                editable: false,
            },
            {
                name: "products",
                type: "searchmultiSelect",
                label: "Select Products",
                options: [],
                mandatory: true,
                showInCreate: true,
                schema: multiSelectSchema,
                editable: true,
            },
            {
                name: "tenant_name",
                mandatory: true,
                type: "text",
                label: "Tenant Name",
                schema: nameSpaceSchema,
                maxChar: 50,
                editable: true,
                showInCreate: true,
            },
            {
                name: "tenant_code",
                mandatory: true,
                type: "text",
                label: "Tenant Code",
                button: "Generate Code",
                refField: "tenant_name",
                schema: codeSchema,
                maxChar: 5,
                editable: true,
                showInCreate: true,
            },
            {
                name: "primary_contact_name",
                mandatory: true,
                type: "text",
                label: "Contact Name",
                schema: nameSpaceSchema,
                maxChar: 50,
                editable: true,
                showInCreate: true,
            },
            {
                name: "primary_contact_phone",
                mandatory: true,
                type: "phone",
                label: "Contact Phone",
                schema: phoneSchema,
                maxChar: 10,
                editable: true,
                showInCreate: true,
            },
            {
                name: "primary_contact_email",
                mandatory: true,
                type: "email",
                label: "Contact Email",
                schema: emailSchema,
                editable: true,
                showInCreate: true,
            },
            {
                name: "grades",
                mandatory: true,
                type: "searchmultiSelect",
                label: "Select Grades",
                options: [],
                schema: multiSelectSchema,
                editable: true,
                showInCreate: true,
            },
            // {
            //     name: "source",
            //     mandatory: false,
            //     type: "text",
            //     label: "Source",
            //     showInCreate: false,
            //     editable: true,
            // },
            {
                name: "source",
                type: "searchsingleSelect",
                label: "Select Source",
                schema: singleselectSchema,
                mandatory: true,
                options: [
                    { label: "Inmobius", value: "IM" },
                    { label: "Infinity Learn", value: "IL" },
                ],
                showInCreate: true,
                editable: false,
            },
            {
                name: "image",
                type: "image",
                label: "Image",
                editable: false,
                showInCreate: true,
            },
        ],
        searchableItems: [
            // { label: "Tenant Id", value: "tenant_id" },
            { label: "Tenant Name", value: "name" },
            { label: "Tenant Phone", value: "phone" },
            { label: "Tenant Code", value: "code" },
            { label: "Tenant Contact Name", value: "contact_name" },
            { label: "Email", value: "email" },
        ],
    },

    schools: {
        actions: {
            create: CreateSchool,
            update: UpdateSchool,
        },
        fields: [
            {
                name: "school_id",
                type: "text",
                label: "School Id",
                showInCreate: false,
                mandatory: true,
                editable: false,
            },
            {
                name: "product_id",
                type: "searchsingleSelect",
                label: "Select Products",
                options: [],
                mandatory: true,
                showInCreate: true,
                schema: singleselectSchema,
                editable: true,
            },
            {
                name: "tenant_id",
                type: "searchsingleSelect",
                label: "Tenant Name",
                showInCreate: true,
                options: [],
                mandatory: true,
                schema: singleselectSchema,
                editable: true,
            },
            {
                name: "school_name",
                type: "text",
                label: "School Name",
                showInCreate: true,
                schema: nameSpaceSchema,
                maxChar: 50,
                mandatory: true,
                editable: true,
            },
            {
                name: "first_name",
                type: "text",
                label: "First Name",
                showInCreate: true,
                schema: nameSpaceSchema,
                maxChar: 50,
                mandatory: true,
                editable: true,
            },
            {
                name: "last_name",
                type: "text",
                label: "Last Name",
                maxChar: 50,
                showInCreate: true,
                mandatory: true,
                schema: nameSpaceSchema,
                editable: true,
            },
            // {
            //     name: "school_logo",
            //     type: "file",
            //     label: "School Logo",
            //     editable: true,
            // },
            {
                name: "phone",
                type: "phone",
                label: "Phone",
                showInCreate: true,
                mandatory: true,
                schema: phoneSchema,
                maxChar: 10,
                editable: true,
            },
            {
                name: "email",
                type: "email",
                label: "Email",
                showInCreate: true,
                schema: emailSchema,
                mandatory: true,
                editable: true,
            },
            // {
            //     name: "password",
            //     type: "password",
            //     label: "Password",
            //     showInCreate: true,
            //     schema: passwordSchema,
            //     mandatory: true,
            //     editable: true,
            // },
            // {
            //     name: "registration_source",
            //     type: "text",
            //     label: "Registration Source",
            //     showInCreate: true,
            //     editable: false,
            // },
            {
                name: "address",
                type: "text",
                label: "Address",
                showInCreate: true,
                mandatory: true,
                editable: true,
            },
            {
                name: "country_id",
                type: "searchsingleSelect",
                label: "Country",
                showInCreate: true,
                mandatory: true,
                options: [],
                change: "countryId",
                editable: true,
            },
            {
                name: "state_id",
                type: "searchsingleSelect",
                label: "State",
                showInCreate: true,
                mandatory: true,
                options: [],
                change: "stateId",
                editable: true,
            },
            {
                name: "city_id",
                type: "searchsingleSelect",
                label: "City",
                showInCreate: true,
                mandatory: true,
                options: [],
                editable: true,
            },
            {
                name: "pincode",
                type: "text",
                label: "Pin Code",
                showInCreate: true,
                editable: true,
                mandatory: true,
            },
            {
                name: "whatsapp_consent",
                type: "radio",
                label: "Whatsapp Consent",
                showInCreate: true,
                options: [
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" },
                ],
                editable: true,
            },
            // {
            //     name: "school_type_id",
            //     type: "singleSelect",
            //     label: "School Type",
            //     mandatory: true,
            //     options: [
            //         { label: "CBSE", value: 0 },
            //         { label: "State", value: 1 },
            //     ],
            //     editable: true,
            // },
        ],
        searchableItems: [
            // { label: "School Id", value: "school_id" },
            { label: "School Name", value: "school_name" },
            { label: "Phone", value: "phone" },
            { label: "Contact Name", value: "contact_name" },
            { label: "Email", value: "email" },
            // { label: "School Code", value: "school_code" },
        ],
    },

    grades: {
        actions: {
            create: createGrades,
            update: updateGrades,
        },
        fields: [
            {
                name: "grade_id",
                type: "text",
                label: "Grade Id",
                showInCreate: false,
                editable: false,
            },
            {
                name: "products",
                type: "searchmultiSelect",
                label: "Select Products",
                options: [],
                mandatory: true,
                showInCreate: true,
                schema: multiSelectSchema,
                editable: false,
            },
            {
                name: "grade_name",
                type: "text",
                label: "Grade Name",
                mandatory: true,
                showInCreate: true,
                schema: noSpecCharSchema,
                editable: false,
            },

            {
                name: "description",
                type: "description",
                label: "Description",
                mandatory: true,
                showInCreate: true,
                maxChar: 50,
                editable: true,
            },
            {
                name: "image",
                type: "image",
                showInCreate: true,
                label: "Image",
                editable: true,
            },
        ],
        searchableItems: [
            { label: "Grade Id", value: "grade_id" },
            { label: "Grade Name", value: "grade_name" },
        ],
    },

    products: {
        actions: {
            create: CreateProduct,
            update: UpdateProduct,
        },
        fields: [
            {
                name: "product_id",
                type: "text",
                label: "Product Id",
                showInCreate: true,
                mandatory: true,
                editable: false,
                schema: productIdSchema,
                maxChar: 5,
            },
            {
                name: "product_name",
                type: "text",
                label: "Product Name",
                showInCreate: true,
                mandatory: true,
                editable: true,
                schema: nameSpaceSchema,
                maxChar: 50,
            },
            {
                name: "product_code",
                type: "text",
                label: "Product Code",
                showInCreate: true,
                button: "Generate Code",
                refField: "product_name",
                mandatory: true,
                editable: true,
                schema: codeSchema,
                maxChar: 5,
            },
            {
                name: "description",
                type: "description",
                label: "Description",
                showInCreate: true,
                maxChar: 50,
                editable: true,
            },
            // {
            //     name: "role_ids",
            //     type: "multiSelect",
            //     label: "Select Roles",
            //     options: [],
            // },
            {
                name: "endpoint_url",
                type: "text",
                label: "Endpoint URL",
                showInCreate: true,
                mandatory: true,
                editable: true,
                schema: URLSchema,
            },
            {
                name: "logo",
                type: "image",
                label: "Product Logo",
                showInCreate: true,
                // mandatory: true,
                editable: true,
            },
        ],
        searchableItems: [
            { label: "Product Id", value: "product_id" },
            { label: "Product Name", value: "product_name" },
            { label: "Product Code", value: "product_code" },
        ],
    },

    roles: {
        actions: {
            create: CreateRole,
            update: UpdateRole,
        },
        fields: [
            {
                name: "role_id",
                type: "text",
                label: "Role Id",
                showInCreate: false,
                editable: false,
            },
            {
                name: "role_name",
                type: "text",
                label: "Role Name",
                showInCreate: true,
                mandatory: true,
                schema: nameSpaceSchema,
                maxChar: 50,
                editable: true,
            },
            // {
            //     name: "role_type",
            //     type: "singleSelect",
            //     label: "Role Type",
            //     mandatory: true,
            //     showInCreate: true,
            //     editable: true,
            //     options: [
            //         { label: "Read Only", value: 1 },
            //         { label: "Write", value: 2 },
            //         { label: "Admin", value: 3 },
            //     ],
            // },
            {
                name: "role_code",
                type: "text",
                label: "Role Code",
                button: "Generate Code",
                showInCreate: true,
                mandatory: true,
                refField: "role_name",
                schema: codeSchema,
                maxChar: 5,
                editable: false,
            },
            {
                name: "display_name",
                type: "text",
                label: "Display Name",
                showInCreate: true,
                mandatory: true,
                schema: nameSpaceSchema,
                maxChar: 50,
                editable: true,
            },
            // {
            //     name: "description",
            //     type: "text",
            //     label: "Description",
            //     showInCreate: true,
            //     editable: true,
            // },
        ],
        searchableItems: [
            { label: "Role Id", value: "role_id" },
            { label: "Role Name", value: "role_name" },
            { label: "Role Code", value: "role_code" },
        ],

        Permissions: [
            {
                type: "heading",
                label1: "Modules",
                label2: "Permissions",
                directionStyles: {
                    display: "Flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "30%",
                    marginBottom: "15px",
                },
            },
            {
                name: "tenants",
                type: "radio",
                label: "Tenants",
                options: [
                    { label: "Allow", value: 1 },
                    { label: "Deny", value: 0 },
                ],
                directionStyles: radioGroupDirectionStylesForRoles,
            },
            {
                name: "schools",
                type: "radio",
                label: "Schools",
                options: [
                    { label: "Allow", value: 1 },
                    { label: "Deny", value: 0 },
                ],
                directionStyles: radioGroupDirectionStylesForRoles,
            },
            {
                name: "grades",
                type: "radio",
                label: "Grades",
                options: [
                    { label: "Allow", value: 1 },
                    { label: "Deny", value: 0 },
                ],
                directionStyles: radioGroupDirectionStylesForRoles,
            },
            {
                name: "products",
                type: "radio",
                label: "Products",
                options: [
                    { label: "Allow", value: 1 },
                    { label: "Deny", value: 0 },
                ],
                directionStyles: radioGroupDirectionStylesForRoles,
            },
            {
                name: "roles",
                type: "radio",
                label: "Roles",
                options: [
                    { label: "Allow", value: 1 },
                    { label: "Deny", value: 0 },
                ],
                directionStyles: radioGroupDirectionStylesForRoles,
            },
            {
                name: "exams",
                type: "radio",
                label: "Exams",
                options: [
                    { label: "Allow", value: 1 },
                    { label: "Deny", value: 0 },
                ],
                directionStyles: radioGroupDirectionStylesForRoles,
            },
            {
                name: "users",
                type: "radio",
                label: "Users",
                options: [
                    { label: "Allow", value: 1 },
                    { label: "Deny", value: 0 },
                ],
                directionStyles: radioGroupDirectionStylesForRoles,
            },
            {
                name: "programs",
                type: "radio",
                label: "Programs",
                options: [
                    { label: "Allow", value: 1 },
                    { label: "Deny", value: 0 },
                ],
                directionStyles: radioGroupDirectionStylesForRoles,
            },
            {
                name: "agents",
                type: "radio",
                label: "Agents",
                options: [
                    { label: "Allow", value: 1 },
                    { label: "Deny", value: 0 },
                ],
                directionStyles: radioGroupDirectionStylesForRoles,
            },
        ],
    },

    exams: {
        actions: {
            create: CreateExam,
            update: UpdateExam,
        },
        fields: [
            {
                name: "exam_id",
                type: "text",
                label: "Exam Id",
                showInCreate: false,
                editable: false,
            },
            {
                name: "name",
                type: "text",
                label: "Exam name",
                showInCreate: true,
                mandatory: true,
                editable: true,
                schema: nameSpaceSchema,
                maxChar: 50,
            },
            {
                name: "description",
                type: "description",
                label: "Description",
                showInCreate: true,
                maxChar: 50,
                editable: true,
            },
            {
                name: "image",
                type: "image",
                label: "Exam Image",
                showInCreate: true,
                editable: true,
            },
        ],
        searchableItems: [
            { label: "Exam Id", value: "exam_id" },
            { label: "Exam Name", value: "name" },
        ],
    },

    // users: {
    //     products: [
    //         {
    //             name: "Inmobius",
    //             productId: 0,
    //             action: getTenantOptions,
    //             targetId: 0,
    //             fields: [
    //                 {
    //                     id: 0,
    //                     name: "tenant_id",
    //                     type: "select",
    //                     label: "IN Tenant",
    //                     options: [],
    //                     action: getBranchOptions,
    //                     targetId: 1,
    //                 },
    //                 {
    //                     id: 1,
    //                     name: "branch_id",
    //                     type: "select",
    //                     label: "Branch",
    //                     options: [],
    //                     action: getSchoolIds,
    //                     targetId: 2,
    //                 },
    //                 {
    //                     id: 2,
    //                     name: "school_id",
    //                     type: "select",
    //                     label: "School",
    //                     options: [],
    //                     action: getGrades,
    //                     targetId: 3,
    //                 },
    //                 {
    //                     id: 3,
    //                     name: "grade_id",
    //                     type: "select",
    //                     label: "Grade",
    //                     options: [],
    //                 },
    //                 { name: "first_name", type: "text", label: "First Name" },
    //                 { name: "last_name", type: "text", label: "Last Name" },
    //                 { name: "phone", type: "tel", label: "Phone" },
    //                 { name: "email", type: "email", label: "Email" },
    //                 { name: "password", type: "password", label: "Password" },
    //                 { name: "program_id", type: "text", label: "Program" },
    //                 { name: "academic_year", type: "text", label: "Academic Year" },
    //             ],
    //         },
    //         {
    //             name: "Learn",
    //             productId: 1,
    //             action: getTenantOptions,
    //             targetId: 0,
    //             fields: [
    //                 {
    //                     id: 0,
    //                     name: "tenant_id",
    //                     type: "select",
    //                     label: "LN Tenant",
    //                     options: [],
    //                     action: getBranchOptions,
    //                     targetId: 1,
    //                 },
    //                 {
    //                     id: 1,
    //                     name: "branch_id",
    //                     type: "select",
    //                     label: "Branch",
    //                     options: [],
    //                     action: getSchoolIds,
    //                     targetId: 2,
    //                 },
    //                 {
    //                     id: 2,
    //                     name: "school_id",
    //                     type: "select",
    //                     label: "School",
    //                     options: [],
    //                     action: getGrades,
    //                     targetId: 3,
    //                 },
    //                 { id: 3, name: "grade_id", type: "select", label: "Grade", options: [] },
    //                 { name: "first_name", type: "text", label: "First Name" },
    //                 { name: "last_name", type: "text", label: "Last Name" },
    //                 { name: "phone", type: "tel", label: "Phone" },
    //                 { name: "email", type: "email", label: "Email" },
    //                 { name: "password", type: "password", label: "Password" },
    //                 { name: "program_id", type: "text", label: "Program" },
    //                 { name: "academic_year", type: "text", label: "Academic Year" },
    //             ],
    //         },
    //         {
    //             name: "Meta",
    //             productId: 1,
    //             action: getTenantOptions,
    //             targetId: 0,
    //             fields: [
    //                 {
    //                     id: 0,
    //                     name: "tenant_id",
    //                     type: "select",
    //                     label: "M Tenant",
    //                     options: [],
    //                     action: getBranchOptions,
    //                     targetId: 1,
    //                 },
    //                 {
    //                     id: 1,
    //                     name: "branch_id",
    //                     type: "select",
    //                     label: "Branch",
    //                     options: [],
    //                     action: getSchoolIds,
    //                     targetId: 2,
    //                 },
    //                 {
    //                     id: 2,
    //                     name: "school_id",
    //                     type: "select",
    //                     label: "School",
    //                     options: [],
    //                     action: getGrades,
    //                     targetId: 3,
    //                 },
    //                 { id: 3, name: "grade_id", type: "select", label: "Grade", options: [] },
    //                 { name: "first_name", type: "text", label: "First Name" },
    //                 { name: "last_name", type: "text", label: "Last Name" },
    //                 { name: "phone", type: "tel", label: "Phone" },
    //                 { name: "email", type: "email", label: "Email" },
    //                 { name: "password", type: "password", label: "Password" },
    //                 { name: "program_id", type: "text", label: "Program" },
    //                 { name: "academic_year", type: "text", label: "Academic Year" },
    //             ],
    //         },
    //     ],
    //     searchableItems: [
    //         { label: "User Id", value: "user_id" },
    //         { label: "User Name", value: "user_name" },
    //     ],
    // },

    programs: {
        actions: {
            create: CreateProgram,
            update: UpdateProgram,
        },
        fields: [
            {
                name: "program_id",
                type: "text",
                label: "Program Id",
                showInCreate: false,
                mandatory: true,
                editable: false,
                schema: productIdSchema,
                // maxChar: 5,
            },
            {
                name: "program_name",
                type: "text",
                label: "Program Name",
                showInCreate: true,
                mandatory: true,
                editable: true,
                schema: nameSpaceSchema,
                maxChar: 50,
            },
            {
                name: "product_ids",
                type: "searchmultiSelect",
                label: "Select Products",
                options: [],
                showInCreate: true,
                mandatory: true,
                editable: true,
                schema: multiSelectSchema,
            },
            // {
            //     name: "description",
            //     type: "text",
            //     label: "Description",
            //     editable: true,
            //     schema: nameSpaceSchema,
            //     showInCreate: true,
            //     maxChar: 50,
            // },
            {
                name: "exam_type",
                type: "searchsingleSelect",
                label: "Exam Type",
                mandatory: true,
                schema: singleselectSchema,
                showInCreate: true,
                editable: true,
                options: [],
            },
            {
                name: "grade_ids",
                type: "searchmultiSelect",
                label: "Select Grades",
                showInCreate: true,
                mandatory: true,
                schema: multiSelectSchema,
                editable: true,
                options: [],
            },
        ],
        searchableItems: [
            { label: "Program Id", value: "program_id" },
            { label: "Program Name", value: "program_name" },
            { label: "Exam Type", value: "exam_type" },
        ],
    },

    agents: {
        actions: {
            create: CreateAgents,
            update: UpdateAgent,
        },
        fields: [
            {
                name: "products",
                type: "searchmultiSelect",
                label: "Select Products",
                options: [],
                showInCreate: true,
                schema: multiSelectSchema,
                mandatory: true,
                editable: true,
            },
            {
                name: "admission_number",
                type: "text",
                label: "Admission Number",
                showInCreate: true,
                schema: noSpecCharSchema,
                maxChar: 50,
                mandatory: true,
                editable: false,
                button: "Generate Code",
                refField: "tenant_name",
            },
            {
                name: "first_name",
                type: "text",
                label: "First Name",
                showInCreate: true,
                schema: nameSpaceSchema,
                maxChar: 50,
                mandatory: true,
                editable: true,
            },
            {
                name: "last_name",
                type: "text",
                label: "Last Name",
                showInCreate: true,
                mandatory: true,
                schema: nameSpaceSchema,
                maxChar: 50,
                editable: true,
            },
            {
                name: "phone",
                type: "text",
                label: "Phone",
                showInCreate: true,
                schema: phoneSchema,
                maxChar: 10,
                mandatory: true,
                editable: true,
            },
            {
                name: "email",
                type: "email",
                label: "Email",
                showInCreate: true,
                schema: emailSchema,
                editable: true,
            },
            {
                name: "password",
                type: "password",
                label: "Password",
                showInCreate: true,
                // schema: passwordSchema,
                // mandatory: true,
                editable: true,
            },
            {
                name: "roles",
                type: "searchmultiSelect",
                label: "Select Role",
                showInCreate: true,
                options: [],
                schema: multiSelectSchema,
                mandatory: true,
                editable: true,
            },
            {
                name: "dob",
                type: "dob",
                label: "DOB",
                showInCreate: true,
                mandatory: true,
                editable: true,
            },
            {
                name: "description",
                type: "description",
                label: "Description",
                showInCreate: true,
                maxChar: 50,
                editable: true,
            },
            {
                name: "gender",
                type: "radio",
                label: "Gender",
                showInCreate: true,
                options: [
                    { label: "Male", value: "M" },
                    { label: "Female", value: "F" },
                    // { label: "Other", value: "Other" },
                ],
                editable: true,
            },
            {
                name: "profile_photo",
                type: "image",
                label: "Profile Image",
                showInCreate: true,
                editable: true,
            },
        ],
        searchableItems: [
            { label: "Admission Id", value: "admission_id" },
            // { label: "Agent Name", value: "agent_name" },
            { label: "Phone", value: "phone" },
        ],
    },

    users: {
        actions: {
            create: null,
            update: updateUser,
        },
        fields: [
            {
                name: "product_id",
                type: "searchsingleSelect",
                label: "Product",
                options: [],
                showInCreate: true,
                schema: multiSelectSchema,
                mandatory: true,
                editable: false,
            },
            {
                name: "admission_number",
                type: "text",
                label: "Admission Number",
                showInCreate: true,
                schema: noSpecCharSchema,
                maxChar: 50,
                mandatory: true,
                editable: false,
                button: "Generate Code",
                refField: "tenant_name",
            },
            {
                name: "first_name",
                type: "text",
                label: "First Name",
                showInCreate: true,
                schema: nameSpaceSchema,
                maxChar: 50,
                mandatory: true,
                editable: true,
            },
            {
                name: "last_name",
                type: "text",
                label: "Last Name",
                showInCreate: true,
                mandatory: true,
                schema: nameSpaceSchema,
                maxChar: 50,
                editable: true,
            },
            {
                name: "phone",
                type: "text",
                label: "Phone",
                showInCreate: true,
                schema: phoneSchema,
                maxChar: 10,
                mandatory: true,
                editable: true,
            },
            {
                name: "email",
                type: "email",
                label: "Email",
                showInCreate: true,
                schema: emailSchema,
                editable: true,
            },
            {
                name: "grade",
                mandatory: true,
                type: "searchsingleSelect",
                label: "Grade",
                options: [],
                schema: singleselectSchema,
                // schema: multiSelectSchema,
                editable: true,
                showInCreate: true,
            },
            {
                name: "crn_id",
                type: "text",
                label: "CRN Id",
                mandatory: true,
                showInCreate: false,
                editable: false,
            },
            {
                name: "tenant_id",
                type: "searchsingleSelect",
                label: "Tenant Name",
                showInCreate: true,
                options: [],
                mandatory: true,
                schema: singleselectSchema,
                editable: true,
            },
            {
                name: "password",
                type: "password",
                label: "Password",
                showInCreate: true,
                // schema: passwordSchema,
                // mandatory: true,
                editable: true,
            },
        ],
        searchableItems: [
            { label: "Admission Number", value: "admission__num" },
            // { label: "Agent Name", value: "agent_name" },
            { label: "Phone", value: "phone" },
        ],
    },
};
