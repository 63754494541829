import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "./auth.api";

export const USER_TYPES = Object.freeze({
  SCHOOL: "school",
  STUDENT: "student",
  TEACHER: "teacher"
});

const initialState = {
  user: null,
  userType: null,
  tokens: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLogout(state) {
      state.user = initialState.user;
      state.userType = initialState.userType;
      state.tokens = initialState.tokens;
    },
    setUserActive(state) {
      state.user.is_active = true;
    }
  },
  extraReducers: (builder) => {}
});

export const { userLogout, setUserActive } = authSlice.actions;
export default authSlice.reducer;


