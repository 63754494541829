import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import InfoCard from "./InfoCard";
import { dashboardCards } from "../../constants/cardsArray";
import { isAuthenticated } from "../../utils/auth";
import { CommonApiDataContext } from "../../context/CommonApiDataContext";

const MainDashboard = () => {
    const { dashboardData, loading } = useContext(CommonApiDataContext);
    const history = useHistory();

    // Check if the user is authenticated on component mount
    useEffect(() => {
        document.title = "Gateway Admin | Dashboard";
        if (!isAuthenticated()) {
            // User is not authenticated, redirect to the login page
            history.push("/login");
        }
    }, [history]);

    return (
        <section className="section">
            <div className="dashboard">
                {dashboardCards.cards.map((item) => (
                    <InfoCard key={item.id} {...item} data={dashboardData} loading={loading} />
                ))}
            </div>
        </section>
    );
};

export default MainDashboard;
