import SchoolIcon from "@mui/icons-material/School";
import Typography from "@mui/material/Typography";
import GroupsIcon from "@mui/icons-material/Groups";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

export const dashboardCards = {
    cards: [
        {
            id: "tenants",
            title: "Tenants",
            icon: <GroupsIcon sx={{ fontSize: 35 }} />,
            value: "tenantCount",
            description: "Tenants",
            color: "#4154f1",
            background: "#d7dbf7",
        },
        {
            id: "schools",
            title: "Schools",
            icon: <SchoolIcon sx={{ fontSize: 35 }} />,
            value: "schoolCount",
            description: "Schools",
            color: "#2eca6a",
            background: "#e0f8e9",
        },
        {
            id: "grades",
            title: "Grades",
            icon: <WorkspacePremiumRoundedIcon sx={{ fontSize: 35 }} />,
            value: "gradeCount",
            description: "Grades",
            color: "#ff771d",
            background: "#ffecdf",
        },
        {
            id: "products",
            title: "Products",
            icon: <CategoryRoundedIcon sx={{ fontSize: 35 }} />,
            value: "productCount",
            description: "Products",
            color: "#ff771d",
            background: "#ffecdf",
        },
        {
            id: "exams",
            title: "Exams",
            icon: <ModeEditIcon sx={{ fontSize: 35 }} />,
            value: "examCount",
            description: "Exams",
            color: "#4154f1",
            background: "#d7dbf7",
        },
        {
            id: "programs",
            title: "Programs",
            icon: <BusinessCenterRoundedIcon sx={{ fontSize: 35 }} />,
            value: "programCount",
            description: "Programs",
            color: "#2eca6a",
            background: "#e0f8e9",
        },
        {
            id: "roles",
            title: "Roles",
            icon: <ManageAccountsIcon sx={{ fontSize: 35 }} />,
            value: "roleCount",
            description: "Roles",
            color: "#0ffac3",
            background: "#dcf7f1",
        },
    ],

    Skeletons: {
        title: (
            <Typography variant="h6" width="90%">
                <Skeleton animation="wave" height="22px" />
            </Typography>
        ),
        icon: (
            <Skeleton animation="wave" variant="circular">
                <Avatar sx={{ width: "70px", height: "70px" }} />
            </Skeleton>
        ),
        value: (
            <Typography variant="body1" width="50%">
                <Skeleton animation="wave" height="25px" />
            </Typography>
        ),
        description: (
            <Typography variant="caption" width="100%">
                <Skeleton animation="wave" height="18px" />
            </Typography>
        ),
    },
};
