import { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useParams, Link } from "react-router-dom";
import { getSingleJobDetails } from "../../../utils";

const SingleJobDetail = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    useEffect(() => {
        const getSingleJobData = async () => {
            const response = await getSingleJobDetails(id);
            setData(response);
        };
        getSingleJobData();
    }, [id]);
    return (
        <section className="section">
            <div className="title-createbtn-box">
                <h1 className="title">Users / bulk upload</h1>
            </div>
            <div className="table-card">
                <Link to="/users-bulk-upload-history">
                    <KeyboardBackspaceIcon /> Back
                </Link>
                <div className="counts-box">
                    <div className="total-box">
                        <h3>Total Count: </h3>
                        <h4>{data.length || "NA"}</h4>
                    </div>
                    <div className="succes-box">
                        <h3>Success Count: </h3>
                        <h4>{data?.filter((e) => e.status === "success").length || "NA"}</h4>
                    </div>
                    <div className="succes-box">
                        <h3>Failure Count: </h3>
                        <h4>{data.length - data?.filter((e) => e.status === "success").length || "NA"}</h4>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Admission Number</th>
                            <th>UAM ID</th>
                            <th>Status</th>
                            <th>Failure Reason</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 &&
                            data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.admission_number || "---"}</td>
                                    <td>{item.uam_id || "---"}</td>
                                    <td
                                        style={{
                                            color: item.status === "success" ? "green" : "red",
                                        }}>
                                        {item.status || "---"}
                                    </td>
                                    <td>{item.failure_reason || "---"}</td>
                                    <td>{item.created_at || "---"}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                {data.length === 0 && (
                    <div style={{ width: "100%", textAlign: "center", marginTop: "30px" }}>
                        <h4>No Data Found!</h4>
                    </div>
                )}
            </div>
        </section>
    );
};

export default SingleJobDetail;
