import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const ModalDialog = ({ open, onClose, title, children }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className={title && "view-edit-modal-body"}>
                {title && (
                    <div className="modal-title-close-btn-box">
                        <h1 className="modal-title">{title}</h1>
                        <IconButton color="primary" aria-label="close modal" onClick={onClose}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </div>
                )}
                <div className="modal-data-box">{children}</div>
            </Box>
        </Modal>
    );
};

export default ModalDialog;
