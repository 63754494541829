import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import Badge from "@mui/material/Badge";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import Select from "@mui/material/Select";
import { TableRow } from "./TableRow";
import { TableConfig } from "./TableConfig";
import { itemsPerPageOptions } from "../../constants";
import { CommonApiDataContext } from "../../context/CommonApiDataContext";
import { CONFIG_VARS } from "../../config/globleConfig";
import ModalDialog from "../../components/modal/modalDialog";
import CustomButton from "../../components/buttons/CustomButton";
import { moduleConfig } from "../../config";
import { GetSearchParams } from "../../utils/reusedMethods";

const Table = (props) => {
    const {
        data,
        loading,
        onToggle,
        updateItem,
        pageTitle,
        navigationId,
        EditForm,
        setCurrPage,
        setLimit,
        currPage,
        totalPages,
        limit,
        setProductIds,
        setData,
        sort,
        setSort,
        setSearch,
        search,
        onSearch,
    } = props;

    const [selectedProduct, setSelectedProduct] = useState();
    const [filterMdlOpen, setFilterMdlOpen] = useState(false);
    const [filtersList, setFiltersList] = useState([
        { id: 0, column: moduleConfig[pageTitle.toLowerCase()]?.searchableItems[0]?.value, value: null },
    ]);

    const history = useHistory();
    const searchParams = GetSearchParams();

    const { products, handleOpenSnackbar, access } = useContext(CommonApiDataContext);

    useEffect(() => {
        // const searchParams = new URLSearchParams(location.search);
        const product_id = searchParams.get("product_id");
        const inmobiusProd = getProds();
        product_id
            ? setSelectedProduct(product_id)
            : setSelectedProduct((inmobiusProd && inmobiusProd[0]?.value) || "");
    }, [products, searchParams]);

    /*@TODO: need remove in final stage */
    const getProds = () => {
        if (access && access.includes("inmobius")) {
            return products.filter((p) => p.value === CONFIG_VARS.inmobiusProdId);
        } else if (pageTitle === "Schools") {
            // filtering products for IL Schools(500) and Inmobius(100) in schools module
            return products.filter((p) => CONFIG_VARS.schoolsProds.includes(p.value));
        } else if (access && access === "learnSuperAdmin") {
            return products.filter((p) => CONFIG_VARS.learnSuperAdminProds.includes(Number(p.value)));
        } else if (access && access === "ILSchoolsSuperAdmin") {
            return products.filter((p) => CONFIG_VARS.ILSchoolsSuperAdminProds.includes(Number(p.value)));
        } else {
            return products;
        }
    };

    // useEffect for multiselect filter

    // useEffect(() => {
    //     setProdArr([products[0]]);
    // }, [products]);

    // const handleChange = (event) => {
    //     const value = event.target.value;
    //     if (value[value.length - 1] === "all") {
    //         const opts = prodArr.length === products.length ? [] : products;
    //         setProductIds(opts.map((e) => e.value));
    //         setProdArr(opts);
    //         return;
    //     }
    //     setProductIds && setProductIds(value.map((e) => e.value).join(","));
    //     setProdArr(value);
    // };

    const handleChange = (value) => {
        const searchParams = new URLSearchParams();
        searchParams.set("product_id", value);
        // searchParams.set("param2", "value2");
        // Modify the URL without reloading the page
        history.push({ search: searchParams.toString() });
        setProductIds && setProductIds(`${value}`);
        setSelectedProduct(value);
        setCurrPage(1);
    };

    const handleSearchChange = (event) => {
        setCurrPage(1);
        setSearch((prev) => ({ ...prev, searchText: event.target.value }));
    };

    const handleSearchData = (e) => {
        if (e.code === "Enter") {
            search.searchText !== ""
                ? onSearch && onSearch()
                : handleOpenSnackbar(true, `${search.searchBy} can not be empty!`, "warning");
        }
    };

    const pageChangeHandler = (event, value) => {
        setCurrPage(value);
    };

    const limitChangeHandler = (e) => {
        setCurrPage(1);
        setLimit(parseInt(e.target.value));
    };

    const ToggleSort = () => {
        sort && sort === "asc" ? setSort("desc") : setSort("asc");
        setCurrPage(1);
    };

    // ==================== advanced filter methods starts ====================
    const openFilterMdl = () => {
        setFilterMdlOpen(true);
    };

    const increseFilterItems = () => {
        setFiltersList((prev) => [
            ...prev,
            { id: prev.length, column: moduleConfig[pageTitle.toLowerCase()]?.searchableItems[0].value, value: null },
        ]);
    };

    const removeAllFilters = () => {
        setFiltersList([
            { id: 0, column: moduleConfig[pageTitle.toLowerCase()]?.searchableItems[0].value, value: null },
        ]);
    };

    const handleChangeFilterItem = (id, val) => {
        const updatedFilterItems = filtersList.map((filter) => {
            if (filter.id === id) {
                return { ...filter, column: val };
            }
            return filter;
        });

        setFiltersList(updatedFilterItems);
    };

    const removeFilterItem = (id) => {
        if (filtersList?.length === 1) {
            setFilterMdlOpen(false);
            removeAllFilters();
        } else {
            const filteredItems = filtersList.filter((filter) => filter.id !== id);
            setFiltersList(filteredItems);
        }
    };

    const getFiltersLen = () => {
        if (filtersList[0]?.value === null && filtersList?.length === 1) {
            return 0;
        } else {
            return filtersList?.length;
        }
    };

    const handleTextChangeFilterItem = (id, val) => {
        const updatedFilterItems = filtersList.map((filter) => {
            if (filter.id === id) {
                return { ...filter, value: val };
            }
            return filter;
        });

        setFiltersList(updatedFilterItems);
    };

    const applyAllFilters = () => {
        const filteredItems = filtersList.filter((filter) => filter.value !== null && filter.value !== "");
        setFiltersList(filteredItems);
    };

    const renderFilterItems = () => {
        return filtersList.map((filter, i) => (
            <div className="filter-item-box" key={filter.id}>
                <CustomButton icon={<CloseIcon />} action={removeFilterItem} actionVal={filter.id} color="warning" />
                {i > 0 && (
                    <FormControl sx={{ width: "80px" }} size="small">
                        <InputLabel id={`floating_column_Select_disabled_${i}`}>And</InputLabel>

                        <Select
                            disabled
                            labelId={`floating_column_Select_disabled_${i}`}
                            id={`floating_column_${i}`}
                            name="Specifiler"
                            label="And"></Select>
                    </FormControl>
                )}
                <FormControl sx={{ width: "180px" }} size="small">
                    <InputLabel id={`floating_column_Select_${i}`}>Columns</InputLabel>

                    <Select
                        MenuProps={{
                            PaperProps: { sx: { maxHeight: 220, maxWidth: 170 } },
                        }}
                        labelId={`floating_column_Select_${i}`}
                        id={`floating_column_${i}`}
                        name="columns"
                        value={filter.column}
                        label="Columns"
                        onChange={(e) => handleChangeFilterItem(filter.id, e.target.value)}>
                        {moduleConfig[pageTitle.toLowerCase()]?.searchableItems.map((option) => (
                            <MenuItem key={option.value} value={option.value} sx={{ fontSize: "13px" }}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    id={`outlined-basic-${i}`}
                    label="Value"
                    variant="outlined"
                    sx={{ width: "180px" }}
                    size="small"
                    autoFocus
                    value={filter.value}
                    onChange={(e) => handleTextChangeFilterItem(filter.id, e.target.value)}
                />
            </div>
        ));
    };
    // ==================== advanced filter methods ends ====================

    const handleSingleFilterSelect = (val) => {
        setSearch({ searchText: "", searchBy: val });
    };

    const clearFilters = () => {
        setSearch((prev) => ({ ...prev, searchText: pageTitle === "Users" ? null : "" }));
        setLimit(5);
        setCurrPage(1);
    };

    return (
        <div className="table-card">
            {loading ? (
                <div className="loader-container">
                    <ThreeDots type="Oval" color="#4154f1" height={80} width={80} />
                </div>
            ) : (
                <div>
                    <div className={setProductIds ? "product-search-box" : "noproduct-search-box"}>
                        {pageTitle !== "Exams" && pageTitle !== "Roles" && pageTitle !== "Products" ? (
                            <FormControl sx={{ width: "180px" }} size="small">
                                <InputLabel id={`floating_Product_Select`}>Select Product</InputLabel>
                                <Select
                                    MenuProps={{
                                        PaperProps: { sx: { maxHeight: 220, maxWidth: 170 } },
                                    }}
                                    labelId={`floating_Product_Select`}
                                    id={`floating_Product`}
                                    name="product"
                                    value={selectedProduct}
                                    label="Select Product"
                                    onChange={(e) => handleChange(e.target.value)}>
                                    {/*@TODO: need remove in final stage */}
                                    {products.length > 0 &&
                                        getProds().map((option) => (
                                            <MenuItem key={option.value} value={option.value} sx={{ fontSize: "13px" }}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        ) : // <FormControl sx={{ width: "250px" }}>
                        //      <InputLabel id="mutiple-select-label">Select Products</InputLabel>
                        //     <Select
                        //         labelId="mutiple-select-label"
                        //         multiple
                        //         value={prodArr}
                        //         onChange={handleChange}
                        //         input={<OutlinedInput label="Select Products" />}
                        //         //   renderValue={(selected) =>
                        //         //       products && selected.map((e) => e?.label).join(", ")
                        //         //   }
                        //         renderValue={(selected) =>
                        //             selected?.length === products?.length
                        //                 ? "Selected All"
                        //                 : selected.map((e) => e?.label).join(", ")
                        //         }
                        //         MenuProps={MenuProps}>
                        //         <MenuItem value="all">
                        //             <ListItemIcon>
                        //                 <Checkbox
                        //                     checked={products.length > 0 && prodArr.length === products.length}
                        //                     indeterminate={prodArr.length > 0 && prodArr.length < products.length}
                        //                 />
                        //             </ListItemIcon>
                        //             <ListItemText primary="Select All" />
                        //         </MenuItem>
                        //         {products.map((option) => (
                        //             <MenuItem key={option.value} value={option}>
                        //                 <ListItemIcon>
                        //                     <Checkbox checked={prodArr.indexOf(option) > -1} />
                        //                 </ListItemIcon>
                        //                 <ListItemText primary={option.label} />
                        //             </MenuItem>
                        //         ))}
                        //     </Select>
                        // </FormControl>
                        null}
                        <div
                            className="search-filter-box"
                            style={{
                                display: onSearch ? "flex" : "none",
                            }}>
                            <FormControl sx={{ width: "180px" }} size="small">
                                <InputLabel id={`floating_single_filter_select_lable_${pageTitle}`}>
                                    Search By
                                </InputLabel>

                                <Select
                                    MenuProps={{
                                        PaperProps: { sx: { maxHeight: 220, maxWidth: 170 } },
                                    }}
                                    labelId={`floating_single_filter_select_lable_${pageTitle}`}
                                    id={`floating_silgle_filter_select_${pageTitle}`}
                                    name="columns"
                                    value={search.searchBy}
                                    label="Search By"
                                    onChange={(e) => handleSingleFilterSelect(e.target.value)}>
                                    {moduleConfig[pageTitle.toLowerCase()]?.searchableItems.map((option) => (
                                        <MenuItem key={option.value} value={option.value} sx={{ fontSize: "13px" }}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <div className="search-box">
                                <input
                                    value={search?.searchText}
                                    type="search"
                                    className="search-element"
                                    // maxLength={25}
                                    // autoFocus
                                    placeholder={`${search.searchBy} + \u23ce Key`}
                                    onChange={handleSearchChange}
                                    onKeyDown={handleSearchData}
                                />
                                <SearchIcon className="search-icon" />
                            </div>
                            <CustomButton
                                style={{ display: "none" }} // @TODO: Remove this Style to enable the Advanced filter button
                                startIcon={
                                    <Badge badgeContent={getFiltersLen()} color="primary">
                                        <FilterListIcon />
                                    </Badge>
                                }
                                action={openFilterMdl}
                                text="More Filters"
                                type="button"
                            />

                            {/* Filter modal dialog starts */}
                            <ModalDialog
                                open={filterMdlOpen}
                                onClose={() => setFilterMdlOpen(false)}
                                title={`Filter ${pageTitle}`}>
                                <div className="filter-container">
                                    {renderFilterItems()}
                                    <div className="filters-action-box">
                                        <div>
                                            <CustomButton
                                                startIcon={<AddIcon />}
                                                action={increseFilterItems}
                                                text="Add Filter"
                                                size="small"
                                                color="success"
                                            />
                                            <CustomButton
                                                startIcon={<DeleteForeverIcon />}
                                                action={removeAllFilters}
                                                text="Remove All"
                                                size="small"
                                                color="error"
                                            />
                                        </div>

                                        <CustomButton
                                            variant="contained"
                                            action={applyAllFilters}
                                            text="Apply"
                                            size="small"
                                        />
                                    </div>
                                </div>
                            </ModalDialog>
                            {(search.searchText !== "" && search.searchText !== null) && (
                                <CustomButton
                                    startIcon={<CloseIcon />}
                                    action={clearFilters}
                                    text="Clear Filters"
                                    size="small"
                                />
                            )}
                            {/* Filter modal dialog ends */}
                        </div>
                    </div>

                    {/* ==================== Table starts ==================== */}
                    {TableConfig.tables[navigationId].items?.length > 0 && (
                        <div className="table-resp-box">
                            <table>
                                <thead>
                                    <tr>
                                        {TableConfig.tables[navigationId].items.map((column) => {
                                            const shouldDisplay =
                                                column.displayTable && (column.type !== "toggle" || access !== "other");
                                            return (
                                                shouldDisplay && (
                                                    <th key={column.id}>
                                                        {column.name}
                                                        {column?.sort && (
                                                            <SwapVertIcon className="sort-icon" onClick={ToggleSort} />
                                                        )}
                                                    </th>
                                                )
                                            );
                                        })}
                                        <th>More</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.length > 0 &&
                                        data.map((item) => (
                                            <TableRow
                                                key={item[TableConfig.tables[navigationId].items[0].id]}
                                                id={item.id}
                                                rowConfig={TableConfig.tables[navigationId].items}
                                                item={item}
                                                onToggle={onToggle}
                                                EditForm={EditForm}
                                                updateItem={updateItem}
                                                pageTitle={pageTitle}
                                                handleOpenSnackbar={handleOpenSnackbar}
                                                dta={data}
                                                setData={setData}
                                                access={access}
                                            />
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {/* ==================== Table ends ==================== */}

                    {/* ==================== empty table view starts ==================== */}
                    {data?.length === 0 && (
                        <div className="table-empty-view">
                            <h2>Oops! Data Not Found.</h2>
                        </div>
                    )}
                    {/* ==================== empty table view ends ==================== */}

                    {/* ==================== Table footer starts ==================== */}
                    {data.length !== 0 && (
                        <div className="table-pagn-itemspp-box">
                            <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                                <InputLabel id="demo-select-small-label">Items per Page</InputLabel>
                                <Select
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 120, width: 100 } } }}
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={limit}
                                    label="Items per Page"
                                    onChange={(e) => limitChangeHandler(e)}>
                                    {itemsPerPageOptions.map((option) => (
                                        <MenuItem key={option?.value} value={option?.value}>
                                            {option?.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Pagination
                                variant="outlined"
                                color="primary"
                                count={totalPages}
                                page={currPage}
                                onChange={pageChangeHandler}
                            />
                        </div>
                    )}
                    {/* ==================== Table footer ends ==================== */}
                </div>
            )}
        </div>
    );
};

export default Table;
