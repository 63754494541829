import { useContext, useEffect, useState } from "react";
import DynamicForm from "../../../components/forms/DynamicForm";
import { moduleConfig } from "../../../config";
import { TableConfig } from "../../table/TableConfig";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";

const GradesWrapper = (props) => {
    const { onSubmit, closeModal, data, ModalTitle, dta, setData } = props;
    const [fields, setFields] = useState(moduleConfig.grades.fields);
    const [loader, setLoader] = useState(false);
    const [formData, setFormData] = useState({ ...data });

    const { products } = useContext(CommonApiDataContext);

    useEffect(() => {
        const getAllGradesAndProducts = async () => {
            setLoader(true);
            try {
                const filteredProducts = products?.filter((product) => data?.products?.includes(product?.label));

                data && setFormData((prev) => ({ ...prev, products: filteredProducts }));

                const updatedFields = fields.map((e) => {
                    if (e.options && e.name === "products") {
                        return { ...e, options: products?.filter((e) => e.active) };
                    }
                    return e;
                });

                const reqFields = data ? updatedFields : updatedFields.filter((e) => e.showInCreate && e);

                setFields(reqFields);
                setLoader(false);
            } catch (error) {
                setLoader(false);
            }
        };

        getAllGradesAndProducts();
    }, []);
    return (
        <DynamicForm
            fields={fields}
            onSubmit={onSubmit}
            closeModal={closeModal}
            ModalTitle={ModalTitle}
            data={data && formData}
            loader={loader}
            actions={moduleConfig?.grades?.actions}
            dta={dta}
            setData={setData}
            navId={TableConfig.tables[2].items[0].id}
        />
    );
};

export default GradesWrapper;
