import { useEffect, useState } from "react";
import DynamicForm from "../../../components/forms/DynamicForm";
import { moduleConfig } from "../../../config";
import { TableConfig } from "../../table/TableConfig";

const ProductsWrapper = (props) => {
    const { onSubmit, closeModal, data, ModalTitle, dta, setData } = props;
    const [fields, setFields] = useState(moduleConfig.products.fields);
    const [formData, setFormData] = useState({ ...data });

    useEffect(() => {
        const reqFields = data ? fields : fields.filter((e) => e.showInCreate && e);
        setFields(reqFields);
    }, []);
    return (
        <DynamicForm
            fields={fields}
            onSubmit={onSubmit}
            closeModal={closeModal}
            ModalTitle={ModalTitle}
            data={data && formData}
            actions={moduleConfig?.products?.actions}
            dta={dta}
            setData={setData}
            navId={TableConfig.tables[3].items[0].id}
        />
    );
};

export default ProductsWrapper;
