import React, { useState } from "react";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { dashboardCards } from "../../constants/cardsArray";

const InfoCard = (props) => {
    const { id, title, icon, value, description, color, background, data, loading } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const profileDropdownOpen = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="info-card" key={id}>
            <div className="cardName-menu-box">
                {loading ? dashboardCards.Skeletons.title : <h5 className="card-name">{title}</h5>}
                {/* {!loading && <MoreHorizIcon onClick={handleClick} className="table-control-icons" />} */}
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={profileDropdownOpen}
                    onClose={handleClose}
                    onClick={handleClose}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                    <MenuItem disabled selected={false} onClick={handleClose}>
                        Actions
                    </MenuItem>
                    <Divider />
                    <Link to={id}>
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <VisibilityRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            View
                        </MenuItem>
                    </Link>
                </Menu>
            </div>

            <div className="card-icon--count--box">
                {loading ? (
                    dashboardCards.Skeletons.icon
                ) : (
                    <div style={{ color: `${color}`, background: `${background}` }} className="icon-box">
                        {icon}
                    </div>
                )}
                <div className="card-value__text__box">
                    {loading ? dashboardCards.Skeletons.value : <h6>{data[value]}</h6>}
                    {loading ? dashboardCards.Skeletons.description : <span className="text-mute">{description}</span>}
                </div>
            </div>
        </div>
    );
};

export default InfoCard;
