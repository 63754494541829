import { useContext, useEffect, useState } from "react";
import DynamicForm from "../../../components/forms/DynamicForm";
import { moduleConfig } from "../../../config";
import { TableConfig } from "../../table/TableConfig";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";
import { CONFIG_VARS } from "../../../config/globleConfig";

const AgentsWrapper = (props) => {
    const { onSubmit, closeModal, data, ModalTitle, dta, setData } = props;
    const [fields, setFields] = useState(moduleConfig.agents.fields);
    const [formData, setFormData] = useState({ ...data });
    const [loader, setLoader] = useState(false);

    const { products, roles, handleOpenSnackbar, access } = useContext(CommonApiDataContext);

    useEffect(() => {
        const getAllRolesAndProducts = async () => {
            setLoader(true);
            try {
                // const roles = await GetRoles(1, 100);
                // const products = await getProducts(1, 100);
                // const updatedProducts = products.products?.map((e) => ({ label: e.product_name, value: e.product_id }));

                const filteredProducts = products?.filter((product) => data?.products.includes(product.value));
                const filteredRoles = roles?.filter((role) => data?.roles?.includes(role.label));
                data && setFormData((prev) => ({ ...prev, products: filteredProducts, roles: filteredRoles }));

                const updatedFields = fields.map((e) => {
                    if (e.options && e.name === "products") {
                        return { ...e, options: getProds()?.filter((e) => e.active) };
                    } else if (e.options && e.name === "roles") {
                        return { ...e, options: roles?.filter((e) => e.active) };
                    }
                    return e;
                });

                const reqFields = data ? updatedFields : updatedFields.filter((e) => e.showInCreate && e);

                setFields(reqFields);
                setLoader(false);
            } catch (error) {
                handleOpenSnackbar(true, error?.response?.data?.detail, "error");
                setLoader(false);
            }
        };

        getAllRolesAndProducts();
    }, []);

    /*@TODO: need remove in final stage */
    const getProds = () => {
        if (access && access.includes("inmobius")) {
            return products.filter((p) => p.value === CONFIG_VARS.inmobiusProdId);
        } else if (access && access === "learnSuperAdmin") {
            return products.filter((p) => CONFIG_VARS.learnSuperAdminProds.includes(Number(p.value)));
        } else if (access && access === "ILSchoolsSuperAdmin") {
            return products.filter((p) => CONFIG_VARS.ILSchoolsSuperAdminProds.includes(Number(p.value)));
        } else {
            return products;
        }
    };

    return (
        <DynamicForm
            fields={fields}
            onSubmit={onSubmit}
            closeModal={closeModal}
            ModalTitle={ModalTitle}
            data={data && formData}
            loader={loader}
            actions={moduleConfig?.agents?.actions}
            dta={dta}
            setData={setData}
            navId={TableConfig.tables[8].items[0].id}
        />
    );
};

export default AgentsWrapper;
