import React from "react";
import Navbar from "./Navbar";
import SideMenu from "./SideMenu";

export const Main = () => {
    return (
        <React.Fragment>
            <Navbar />
            <SideMenu />
        </React.Fragment>
    );
};
