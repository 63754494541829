import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import { linksData } from "../constants/linksData";
import { CONFIG_VARS } from "../config/globleConfig";

const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(false);
    const { pathname } = useLocation();
    const access = localStorage.getItem("access");

    const toggleCollapse = (isDashboard) => {
        setCollapsed(isDashboard || !collapsed);
    };

    const isLinkActive = (route) => (route === pathname ? "active-nav" : null);

    const getLinks = (links) => {
        if (access in CONFIG_VARS.moduleAccessMap) {
            return links.filter((link) => CONFIG_VARS.moduleAccessMap[access].includes(link.name));
        } else {
            return [];
        }
    };

    const renderLink = (link) => (
        <ListItem
            button
            key={link.title}
            sx={{
                marginBottom: "5px",
                "& .MuiTypography-root": {
                    fontSize: "0.9rem",
                    fontWeight: 600,
                    letterSpacing: "0.03rem",
                    // Customize the font size here
                },
            }}
            component={RouterLink}
            to={link.route}
            className={`${isLinkActive(link.route)}`}
            onClick={() => toggleCollapse(link.route === "/dashboard")}>
            <ListItemIcon>
                {React.cloneElement(link.menuIcon, {
                    sx: {
                        color: isLinkActive(link.route) ? "#4154f1" : "#505252", // Set the active color here
                        fontSize: "20px",
                        marginLeft: "10px",
                    },
                })}
            </ListItemIcon>
            <ListItemText primary={link.title} />
        </ListItem>
    );

    const renderNestedLinks = (nestedLinks) => (
        <List>
            {getLinks(nestedLinks).map((subLink, subIndex) => (
                <ListItem
                    button
                    key={subLink.name}
                    sx={{
                        "& .MuiTypography-root": {
                            fontSize: "0.8rem",
                            letterSpacing: "0.03rem",
                            // Customize the font size here
                        },
                    }}
                    className={`${isLinkActive(subLink.route)}`}
                    component={RouterLink}
                    to={subLink.route}>
                    <ListItemIcon>
                        {isLinkActive(subLink.route) === "active-nav" ? (
                            <RadioButtonCheckedOutlinedIcon
                                sx={{
                                    width: "15px",
                                    marginLeft: "30px",
                                    color: "#4154f1",
                                }}
                            />
                        ) : (
                            <CircleOutlinedIcon sx={{ width: "15px", marginLeft: "30px" }} />
                        )}
                    </ListItemIcon>
                    <ListItemText primary={subLink.name} />
                </ListItem>
            ))}
        </List>
    );

    return (
        <Drawer variant="permanent" className="sidebar">
            <List className="sidebar">
                {linksData.map((item, index) => (
                    <div key={index}>
                        {item.links ? (
                            <div>
                                <ListItem
                                    button
                                    sx={{
                                        "& .MuiTypography-root": {
                                            fontSize: "0.9rem",
                                            fontWeight: 600,
                                            letterSpacing: "0.03rem",
                                            // Customize the font size here
                                        },
                                    }}
                                    className={`${!collapsed ? "active-nav" : null}`}
                                    onClick={() => toggleCollapse(item.route === "/dashboard")}>
                                    <ListItemIcon>
                                        {React.cloneElement(item.menuIcon, {
                                            sx: {
                                                color: !collapsed ? "#4154f1" : "#000", // Set the active color here
                                                fontSize: "20px",
                                                marginLeft: "10px",
                                            },
                                        })}
                                    </ListItemIcon>
                                    <ListItemText primary={item.title} />
                                    {collapsed ? (
                                        <ExpandLess className={`${isLinkActive("dashboard")} dropdown-icon`} />
                                    ) : (
                                        <ExpandMore className={`${isLinkActive("dashboard")} dropdown-icon`} />
                                    )}
                                </ListItem>
                                <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                                    {renderNestedLinks(item.links)}
                                </Collapse>
                            </div>
                        ) : (
                            renderLink(item)
                        )}
                    </div>
                ))}
            </List>
        </Drawer>
    );
};

export default Sidebar;
