import React, { useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import FormLabel from "@mui/material/FormLabel";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import TextField from "@mui/material/TextField";
import { Avatar, Button } from "@mui/material";
import ModalDialog from "../../components/modal/modalDialog";
import { getAgent } from "../../utils";

export const TableRow = (props) => {
    let { rowConfig, item, onToggle, EditForm, pageTitle, handleOpenSnackbar, setData, dta, access } = props;
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [confModalOpen, setConfModalOpen] = useState(false);
    const [permMdl, setPermMdl] = useState(false);

    const toggleChange = () => {
        onToggle(item);
        setConfModalOpen(false);
    };

    // const handleOpen = async () => {
    //     if (access === "admin") {
    //         handleOpenSnackbar(true, "Access denied!", "error");
    //     } else if (access === "inmobiusUser" && pageTitle === "Schools") {
    //         handleOpenSnackbar(true, "Access denied!", "error");
    //     } else {
    //             setEditModalOpen(true);
    //         if (pageTitle === "Agents") {
    //             const AD = await getSingleAgentData(item?.admission_number);

    //             const UD = dta.map((e) => {
    //                 if (e[rowConfig[0].id] === AD[rowConfig[0].id]) {
    //                     const UI = {
    //                         ...e,
    //                         profile_photo: AD.profile_photo || "",
    //                         description: AD.description || "",
    //                         gender: AD.gender || "",
    //                         dob: dayjs(AD.dob) || null,
    //                     };
    //                     return UI;
    //                 }
    //                 return e;
    //             });
    //             setData(UD);
    //         } else {
    //             setEditModalOpen(true);
    //         }
    //     }
    // };

    // const handleOpen = async () => {
    //     const isAdmin = access === "admin";
    //     const isSchoolPage = access === "inmobiusUser" && pageTitle === "Schools";
    //     const isAgentPage = pageTitle === "Agents";

    //     if (isAdmin || isSchoolPage) {
    //         handleOpenSnackbar(true, "Access denied!", "error");
    //     } else {
    //         if (isAgentPage) {
    //             const agentData = await getSingleAgentData(item?.admission_number);
    //             const updatedData = dta.map((entry) => {
    //                 if (entry[rowConfig[0].id] === agentData[rowConfig[0].id]) {
    //                     return {
    //                         ...entry,
    //                         profile_photo: agentData.profile_photo || "",
    //                         description: agentData.description || "",
    //                         gender: agentData.gender || "",
    //                         dob: dayjs(agentData.dob) || null,
    //                     };
    //                 }
    //                 return entry;
    //             });
    //             setData(updatedData);
    //         }
    //         setEditModalOpen(true);
    //     }
    // };

    const handleOpen = async () => {
        const isAdmin = access === "admin";
        const isSchoolPage = access === "inmobiusUser" && pageTitle === "Schools";
        const isAgentPage = pageTitle === "Agents";

        if (isAdmin || isSchoolPage) {
            handleOpenSnackbar(true, "Access denied!", "error");
        } else {
            try {
                if (isAgentPage) {
                    const agentData = await getSingleAgentData(item?.admission_number);
                    const updatedData = dta.map((entry) => {
                        if (entry[rowConfig[0].id] === agentData[rowConfig[0].id]) {
                            return {
                                ...entry,
                                profile_photo: agentData.profile_photo || "",
                                description: agentData.description || "",
                                gender: agentData.gender || "",
                                dob: agentData.dob ? dayjs(agentData.dob) : "",
                            };
                        }
                        return entry;
                    });
                    setData(updatedData);
                }
                setEditModalOpen(true);
            } catch (error) {
                console.log("uiahius");
                handleOpenSnackbar(true, error?.response?.data?.detail, "error");
            }
        }
    };

    const handleOpenStatusConfModal = () => {
        access === "admin" ? handleOpenSnackbar(true, "Access denied!", "error") : setConfModalOpen(true);
    };

    const handleOpenPermModal = () => {
        access === "admin" ? handleOpenSnackbar(true, "Access denied!", "error") : setPermMdl(true);
    };

    const getFormattedDateTime = (date) => {
        const localTime = moment.utc(date).local().format("YYYY-MM-DD HH:mm:ss"); // use if needed 'MMM D, YYYY h:mm A'
        return localTime;
    };

    const getSingleAgentData = async (admissionId) => {
        const res = await getAgent(localStorage.getItem("accessToken"), admissionId);
        return res;
    };

    const handleOpenViewModal = async () => {
        setViewModalOpen(true);
        if (pageTitle === "Agents") {
            const agentData = await getSingleAgentData(item?.admission_number);

            // updating the additional Agent data for view Agent modal
            const UD = dta.map((e) => {
                if (e[rowConfig[0].id] === agentData[rowConfig[0].id]) {
                    const UI = {
                        ...e,
                        profile_photo: agentData.profile_photo,
                        dob: agentData.dob,
                        description: agentData.description,
                        gender: agentData.gender,
                    };
                    return UI;
                }
                return e;
            });
            setData(UD);
        }
    };

    return (
        <tr key={item[rowConfig[0].id]}>
            {rowConfig.map((i) => {
                switch (i.type) {
                    case "toggle":
                        if (access !== "other") {
                            return (
                                i.displayTable && (
                                    <td key={`table_data_${rowConfig.indexOf(i)}_${item[i.id]}`}>
                                        {
                                            <>
                                                <div
                                                    className="checkbox-wrapper-34"
                                                    key={`switch_${item[rowConfig[0].id]}`}>
                                                    <input
                                                        className="tgl tgl-ios"
                                                        id={item[rowConfig[0].id]}
                                                        type="checkbox"
                                                        checked={item[i.id]}
                                                        onChange={handleOpenStatusConfModal}
                                                    />
                                                    <label className="tgl-btn" htmlFor={item[rowConfig[0].id]}></label>
                                                </div>
                                                <ModalDialog
                                                    key={`confirmModal_${item[rowConfig[0].id]}`}
                                                    open={confModalOpen}
                                                    onClose={() => setConfModalOpen(false)}
                                                    title={`Activate/Deactivate ${pageTitle.slice(
                                                        0,
                                                        pageTitle.length - 1
                                                    )}`}>
                                                    <div className="conf-dlg-box">
                                                        <h2 className="cof-question">{`Are you sure you want to ${
                                                            item[i.id] ? "Deactivate" : "Activate"
                                                        } ${item[rowConfig[1].id]}?`}</h2>
                                                        <div className="yes-no-btn-box">
                                                            <Button
                                                                variant="outlined"
                                                                onClick={() => setConfModalOpen(false)}>
                                                                NO
                                                            </Button>
                                                            <Button variant="outlined" onClick={toggleChange}>
                                                                YES
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </ModalDialog>
                                            </>
                                        }
                                    </td>
                                )
                            );
                        }
                        break;

                    case "default":
                        return (
                            i.displayTable && (
                                <td key={`table_data_${rowConfig.indexOf(i)}_${item[i.id]}`}>{item[i.id] || "--"}</td>
                            )
                        );
                    case "time":
                        const formatedDate = getFormattedDateTime(item[i.id]);
                        return (
                            i.displayTable && (
                                <td key={`table_data_${rowConfig.indexOf(i)}_${item[i.id]}xxxxxxxx`}>
                                    {formatedDate || "--"}
                                </td>
                            )
                        );
                    case "image":
                        return (
                            i.displayTable && (
                                <td key={`table_data_${rowConfig.indexOf(i)}_${item[i.id]}xxxxxxxx`}>
                                    {item[i.id] ? (
                                        <Avatar
                                            sx={{ maxWidth: "30px", maxHeight: "30px" }}
                                            alt="Remy Sharp"
                                            src={item[i.id]}
                                        />
                                    ) : (
                                        "---"
                                    )}
                                </td>
                            )
                        );

                    case "prod&grad":
                        return i.displayTable && item[i.id].length > 0 ? (
                            <td key={`table_data_${rowConfig.indexOf(i)}_${item[i.id]}`} className="two-items-per-row">
                                {item[i.id].map((e, index) => (
                                    <li
                                        className="item"
                                        key={`table_data_${rowConfig.indexOf(i)}_${item[i.id]}_${index}`}>
                                        {e.label}
                                    </li>
                                ))}
                            </td>
                        ) : (
                            <td key={`table_data_${rowConfig.indexOf(i)}_${item[i.id]}`}>---</td>
                        );
                    default:
                        break;
                }

                return null;
            })}
            <td key={`table_data_more`}>
                <VisibilityRoundedIcon
                    onClick={handleOpenViewModal}
                    color="primary"
                    className="table-control-icons"
                    titleAccess="View Item"
                />

                {/* view Modal starts */}
                <ModalDialog
                    key={`viewModal_${item[rowConfig[0].id]}`}
                    open={viewModalOpen}
                    onClose={() => setViewModalOpen(false)}
                    title={`${pageTitle} View`}>
                    {rowConfig.map((i) => {
                        switch (i.type) {
                            case "toggle":
                                return (
                                    <TextField
                                        key={i.id}
                                        id="standard-basic"
                                        value={item[i.id] === true ? "Active" : "Inactive"}
                                        label={i.name}
                                        variant="standard"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        className="view-field-item"
                                    />
                                );
                            case "time":
                                return (
                                    <TextField
                                        key={i.id}
                                        id="standard-basic"
                                        value={getFormattedDateTime(item[i.id])}
                                        label={i.name}
                                        variant="standard"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        className="view-field-item"
                                    />
                                );
                            case "default":
                                return (
                                    <TextField
                                        key={i.id}
                                        id="standard-basic"
                                        value={item[i.id] || "--"}
                                        label={i.name}
                                        variant="standard"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        className="view-field-item"
                                    />
                                );
                            case "image":
                                return (
                                    <div style={{ margin: "10px 13px" }} key={`avatar_${item[rowConfig[0].id]}`}>
                                        <FormLabel id="demo-label" sx={{ marginBottom: "5px" }}>
                                            {i.name}
                                        </FormLabel>
                                        <Avatar
                                            sx={{ width: "100px", height: "100px" }}
                                            alt={item[i.id]}
                                            src={item[i.id]}
                                        />
                                    </div>
                                );
                            default:
                                break;
                        }
                        return null;
                    })}
                </ModalDialog>
                {/* view Modal ends */}

                <>
                    {access !== "other" && (
                        <EditSharpIcon
                            onClick={handleOpen}
                            color="primary"
                            className="table-control-icons"
                            titleAccess="Edit Item"
                        />
                    )}

                    {/* edit modal starts */}
                    <ModalDialog
                        key={`editModal_${item[rowConfig[0].id]}`}
                        open={editModalOpen}
                        onClose={() => setEditModalOpen(false)}>
                        <EditForm
                            data={item}
                            ModalTitle={`Update ${pageTitle.slice(0, pageTitle.length - 1)}`}
                            closeModal={setEditModalOpen}
                            dta={dta}
                            setData={setData}
                            type="default"
                        />
                    </ModalDialog>
                    {/* edit modal ends */}
                </>

                <>
                    {access !== "other" && pageTitle === "Roles" && (
                        <PermDataSettingIcon
                            onClick={handleOpenPermModal}
                            color="primary"
                            className="table-control-icons"
                            titleAccess="Edit Permissions"
                        />
                    )}

                    {/* permissions modal starts */}
                    <ModalDialog
                        key={`permissionsModal_${item[rowConfig[0].id]}`}
                        open={permMdl}
                        onClose={() => setPermMdl(false)}>
                        <EditForm
                            data={item}
                            ModalTitle={`Permissions To ${item[rowConfig[1].id]}`}
                            closeModal={setPermMdl}
                            dta={dta}
                            setData={setData}
                            type="permissions"
                        />
                    </ModalDialog>
                    {/* permissions modal ends */}
                </>
            </td>
        </tr>
    );
};
