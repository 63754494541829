import React, { useContext, useEffect, useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CommonApiDataContext } from "../../context/CommonApiDataContext";
import { UsersBulkUpload, ValidateBulkUploadFile, getSingleJobDetails } from "../../utils";
import { ThreeDotsLoader } from "../loader/Loader";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const steps = ["Select CSV", "Review", "Upload", "Status"];
const mandatoryColumns = [
    "admission_number",
    "isd_code",
    "tenant_id",
    "first_name",
    // "email",
    // "phone",
    "grade_id",
    "product_id",
    // "password",
];

const BulkUpload = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [file, setFile] = useState({ content: null, name: null, rows: null });
    const [loading, setLoading] = useState(false);

    const [editingCell, setEditingCell] = useState(null);
    const [editValue, setEditValue] = useState("");
    const [rowsData, setRowsData] = useState([]);
    const [headerRow, setHeaderRow] = useState([]);
    const [invalidRows, setInvalidRows] = useState([]);
    const [jobId, setJobId] = useState(null);
    const [singleJobDetails, setSingleJobDetails] = useState([]);
    const { handleOpenSnackbar } = useContext(CommonApiDataContext);

    // useEffect(() => {
    //     if (file.content) {
    //         const rows = file.content.split("\n").map((row) => row.split(",").map((i) => i.replace("\r", "")));
    //         setHeaderRow(rows[0]);
    //         setRowsData(rows.slice(1, rows.length - 1));
    //     }
    // }, [file.content]);

    useEffect(() => {
        validateRows();
    }, [rowsData]);

    const cleanedHeaders = headerRow.map((header) => header.trim());
    const missingColumns = mandatoryColumns.filter((col) => !cleanedHeaders.includes(col));

    const validateRows = () => {
        const invalid = [];
        const phoneColumnIndex = headerRow.findIndex((header) => header.trim() === "phone");

        rowsData.forEach((row, rowIndex) => {
            // let count = 0;
            const vaidators = row[cleanedHeaders.length - 1]?.map((vaidator) => vaidator.field_name);
            row.forEach((cell, columnIndex) => {
                const isMandatoryColumn = mandatoryColumns.includes(cleanedHeaders[columnIndex]);
                const header = cleanedHeaders[columnIndex];
                // console.log(vaidators.includes(header), "1234567890");
                // console.log(header, vaidators);
                const validFromAPI = vaidators?.includes(header);
                if (
                    (isMandatoryColumn && !cell.trim()) ||
                    (columnIndex === phoneColumnIndex && !/^\d{10}$/.test(cell)) ||
                    validFromAPI
                ) {
                    // count += 1;
                    invalid.push({ row: rowIndex, column: columnIndex });
                }
            });
            // if (count > 0) {
            //     row[cleanedHeaders.length] = "FAILED";
            // } else {
            //     row[cleanedHeaders.length] = "PASSED";
            // }
        });

        setInvalidRows(invalid);
    };

    const handleCellClick = (rowIndex, columnIndex, cellValue) => {
        if (invalidRows.some((item) => item.row === rowIndex && item.column === columnIndex)) {
            setEditingCell({ row: rowIndex, column: columnIndex });
            setEditValue(cellValue);
        }
    };

    const handleBlur = (rowIndex, columnIndex, cell) => {
        const newDataRows = [...rowsData];
        const removableValidator = headerRow[columnIndex];
        newDataRows[editingCell.row][editingCell.column] = editValue;
        newDataRows[editingCell.row][cleanedHeaders.length - 1] =
            String(cell) !== String(editValue)
                ? newDataRows[editingCell.row][cleanedHeaders.length - 1]?.filter(
                      (e) => e.field_name !== removableValidator
                  )
                : newDataRows[editingCell.row][cleanedHeaders.length - 1];
        setRowsData(newDataRows);
        setEditingCell(null);
        setEditValue("");
        const UIR = invalidRows.filter((item) => !(item.row === rowIndex && item.column === columnIndex));
        setInvalidRows(UIR);

        // console.log(removableValidator, "cccc");
        // console.log(UIR, "UIR");

        // const res = invalidRows.filter((item) => item.row === editingCell.row);
        // console.log(res);
    };

    const handleDownload = () => {
        const headers = headerRow.filter((item) => item !== "validations");
        const rows = rowsData.map((row) => row.slice(0, headerRow.length - 1));
        const csvContent = [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");
        const blob = new Blob([csvContent], { type: "text/csv", lastModified: new Date() });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "edited_file.csv";
        link.click();
    };

    const handleDownloadStatusData = () => {
        const headers = Object.keys(singleJobDetails[0]);
        const rows = singleJobDetails?.map((obj) => {
            return headers.map((header) => obj[header]);
        });
        const csvContent = [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");
        const blob = new Blob([csvContent], { type: "text/csv", lastModified: new Date() });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${jobId.job_id}.csv`;
        link.click();
    };

    const allCellsValid = invalidRows.length === 0;

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                  steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
        handleDisableNextbtn();
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
        setFile({ content: null, name: null, rows: null });
    };

    // const updateArrayWithValidationRemarks = (array1, array2) => {
    //     array2.forEach((item2) => {
    //         if (!item2.validation_status) {
    //             array1.forEach((item1) => {
    //                 if (item1[0] === item2.admission_number) {
    //                     item1.push(item2.validation_remark);
    //                 }
    //             });
    //         } else {
    //             array1.forEach((item1) => {
    //                 if (item1[0] === item2.admission_number) {
    //                     item1.push("PASSED");
    //                 }
    //             });
    //         }
    //     });
    //     return array1;
    // };

    const handleFileChange = async (event) => {
        try {
            const file = event.target.files[0];
            setFile((prev) => ({ ...prev, name: file && file?.name }));
            if (!file) {
                handleOpenSnackbar(true, "No file selected", "error");
            }
            if (!file.name.endsWith(".csv")) {
                handleOpenSnackbar(true, "Select CSV file format only", "error");
            }

            const reader = new FileReader();
            let rows;
            reader.onload = (e) => {
                const content = e.target.result;
                rows = content.split("\n").map((row) => row.split(",").map((i) => i.replace("\r", "")));
                setFile((prev) => ({ ...prev, content, rows }));
            };
            reader.readAsText(file);

            // const fileData = {
            //     fileobject: file,
            // };
            // const res = await ValidateBulkUploadFile(localStorage.getItem("accessToken"), fileData);
            // setInvalidData(res?.records);

            // const updatedRowsData = updateArrayWithValidationRemarks(rows.slice(1, rows.length - 1), res.records);
            // setRowsData(updatedRowsData);
            vaidateFile(file);
        } catch (error) {
            handleOpenSnackbar(true, error.message, "error");
        }
    };

    const vaidateFile = async (file) => {
        const fileData = {
            fileobject: file,
        };
        try {
            const res = await ValidateBulkUploadFile(localStorage.getItem("accessToken"), fileData, localStorage.getItem("productId"));
            const headers = Object.keys(res?.records[0]);

            const rows = res?.records?.map((obj) => {
                return headers.map((header) => obj[header]);
            });
            // const URD = getUpdatedRows(rows);
            setHeaderRow(headers);
            setRowsData(rows);
            // setInvalidData(res?.records);
        } catch (error) {
            handleOpenSnackbar(true, error.message, "error");
        }
    };

    const onValidateFile = async () => {
        const csvContent = [headerRow.join(","), ...getUpdatedRows(rowsData).map((row) => row.join(","))].join("\n");
        const blob = new File([csvContent], { type: "text/csv", lastModified: new Date() });
        // const fileData = {
        //     fileobject: blob,
        // };

        await vaidateFile(blob);
    };

    const handleDisableNextbtn = () => {
        switch (activeStep) {
            case 0:
                if (file.rows && file.rows.length > 0) {
                    return false;
                } else {
                    return true;
                }
            case 1:
                if (allCellsValid) {
                    return false;
                }
                return true;
            case 2:
                if (jobId) {
                    return false;
                }
                return true;
            default:
                break;
        }
    };

    const getUpdatedRows = (array) => {
        const res = [];
        array.map((row, i) => res.push(row.slice(0, row.length - 1)));
        return res;
    };

    const handleUpload = async () => {
        try {
            setLoading(true);
            const csvContent = [headerRow.join(","), ...getUpdatedRows(rowsData).map((row) => row.join(","))].join(
                "\n"
            );
            const blob = new File([csvContent], { type: "text/csv", lastModified: new Date() });
            const fileData = {
                fileobject: blob,
            };
            const res = await UsersBulkUpload(
                localStorage.getItem("accessToken"),
                fileData,
                localStorage.getItem("productId")
            );
            setJobId(res);
            handleComplete();
            if (res.success) {
                const singleJobDetails = await getSingleJobDetails(res.job_id);
                setSingleJobDetails(singleJobDetails);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const renderSteps = () => {
        switch (activeStep) {
            case 0:
                return (
                    <div className="step-1">
                        <div className="select-file-box">
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}>
                                Select file
                                <VisuallyHiddenInput type="file" accept="text/csv" onChange={handleFileChange} />
                            </Button>
                            {file.name && (
                                <Typography variant="caption" sx={{ display: "inline-block" }}>
                                    {file.name}
                                </Typography>
                            )}
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className="step-2">
                        {!file.content && (
                            <div>
                                <p>No CSV data to display</p>
                            </div>
                        )}

                        {file.content && missingColumns.length > 0 && (
                            <div className="incorrectformat">
                                <p>
                                    CSV file is not in the correct format. Missing columns: {missingColumns.join(", ")}
                                </p>
                            </div>
                        )}

                        {file.content && missingColumns.length === 0 && (
                            <>
                                <div style={{ display: "flex", alignItems: "center", marginLeft: "auto", gap: "10px" }}>
                                    <Button
                                        disabled={!allCellsValid}
                                        variant="contained"
                                        color="success"
                                        sx={{ margin: "10px 0 10px auto" }}
                                        onClick={onValidateFile}>
                                        Validate
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ margin: "10px 0 10px auto" }}
                                        onClick={handleDownload}>
                                        Download
                                    </Button>
                                </div>

                                <div style={{ maxHeight: 550, width: "100%", overflowX: "auto" }}>
                                    <table>
                                        <thead
                                            style={{
                                                position: "sticky",
                                                top: 0,
                                                backgroundColor: "#f0f0f0",
                                                zIndex: 1,
                                            }}>
                                            <tr>
                                                {headerRow.map((cell, index) => (
                                                    <th
                                                        key={index}
                                                        style={{
                                                            maxWidth: "200px",
                                                            fontWeight: "600",
                                                        }}>
                                                        {cell}
                                                    </th>
                                                ))}
                                                {/* <th>Validation Check</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rowsData.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {row.map((cell, columnIndex) => {
                                                        const isInvalid = invalidRows.some(
                                                            (item) =>
                                                                item.row === rowIndex && item.column === columnIndex
                                                        );
                                                        // console.log(isInvalid, rowIndex, columnIndex)
                                                        const isEditing =
                                                            editingCell &&
                                                            editingCell.row === rowIndex &&
                                                            editingCell.column === columnIndex;

                                                        return (
                                                            <td
                                                                key={columnIndex}
                                                                style={{
                                                                    border: isInvalid ? "1px solid red" : "inherit",
                                                                    padding: isEditing ? 0 : "0.7rem",
                                                                }}
                                                                onClick={() =>
                                                                    handleCellClick(rowIndex, columnIndex, cell)
                                                                }>
                                                                {isEditing ? (
                                                                    <input
                                                                        type="text"
                                                                        value={editValue}
                                                                        onChange={(e) => setEditValue(e.target.value)}
                                                                        onBlur={() =>
                                                                            handleBlur(rowIndex, columnIndex, cell)
                                                                        }
                                                                        autoFocus
                                                                        className="tbl-cell-input"
                                                                    />
                                                                ) : (
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                columnIndex === row.length - 1 &&
                                                                                cell?.length === 0
                                                                                    ? "green"
                                                                                    : "",
                                                                        }}>
                                                                        {columnIndex === row.length - 1
                                                                            ? cell?.length > 0
                                                                                ? cell?.map((e) => e?.remark).join(", ")
                                                                                : "PASSED"
                                                                            : cell}
                                                                    </span>
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                    </div>
                );
            case 2:
                return (
                    <div className="step-3">
                        <div className="select-file-box">
                            {file.name && (
                                <Typography variant="caption" sx={{ display: "inline-block" }}>
                                    {file.name}
                                </Typography>
                            )}
                            <Button
                                variant="contained"
                                startIcon={<CloudUploadIcon />}
                                sx={{ marginLeft: "10px" }}
                                onClick={handleUpload}>
                                {loading ? "Loading..." : "Upload"}
                            </Button>
                        </div>
                    </div>
                );
            case 3:
                const successCount = singleJobDetails?.filter((e) => e.status === "success")?.length;
                return (
                    <div className="step-4">
                        {loading ? (
                            ThreeDotsLoader(60, 60, 9, "#48aafa")
                        ) : (
                            <>
                                <div className="counts-box">
                                    <div className="total-box">
                                        <h3>Total Count: </h3>
                                        <h4>{successCount}</h4>
                                    </div>
                                    <div className="succes-box">
                                        <h3>Success Count: </h3>
                                        <h4>{successCount}</h4>
                                    </div>
                                    <div className="succes-box">
                                        <h3>Failure Count: </h3>
                                        <h4>{singleJobDetails.length - successCount}</h4>
                                    </div>
                                </div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ margin: "10px 0 10px auto" }}
                                    onClick={handleDownloadStatusData}>
                                    Download
                                </Button>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Admission Number</th>
                                            <th>UAM ID</th>
                                            <th>Status</th>
                                            <th>Failure Reason</th>
                                            <th>Created At</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {singleJobDetails.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.admission_number}</td>
                                                <td>{item.uam_id}</td>
                                                <td
                                                    style={{
                                                        color: item.status === "success" ? "green" : "red",
                                                    }}>
                                                    {item.status}
                                                </td>
                                                <td>{item.failure_reason || "---"}</td>
                                                <td>{item.created_at}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </div>
                );
            default:
                break;
        }
    };

    return (
        <div className="bulk-upload-dlg-box">
            <Stepper activeStep={activeStep} sx={{ position: "sticky", zIndex: 3 }}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepLabel color="inherit">{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div className="upload-steps">{renderSteps()}</div>
            <div>
                {allStepsCompleted() ? (
                    <React.Fragment>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "600px",
                            }}>
                            <Button onClick={handleReset}>Reset</Button>
                            <Typography>All steps completed - you&apos;re finished</Typography>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", pt: 2 }}>
                            <Button
                                color="primary"
                                disabled={[0, 3].includes(activeStep)}
                                onClick={handleBack}
                                sx={{ mr: 1 }}>
                                Prev step
                            </Button>
                            <Box>
                                {activeStep !== steps.length &&
                                    (completed[activeStep] ? (
                                        <Button onClick={handleNext} sx={{ mr: 1 }} disabled={handleDisableNextbtn()}>
                                            Next
                                        </Button>
                                    ) : (
                                        activeStep !== 2 && (
                                            <Button onClick={handleComplete} disabled={handleDisableNextbtn()}>
                                                {completedSteps() === totalSteps() - 1 ? "Finish" : "Next"}
                                            </Button>
                                        )
                                    ))}
                            </Box>
                        </Box>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default BulkUpload;
