export const CONFIG_VARS = Object.freeze({
  schoolLoginUrl: "https://inmobiusstaging.devinfinitylearn.in/login",
  //schoolLoginUrl: "https://admin.inmobiusinfinitylearn.com/login",
  baseUrl: {
    1: "https://inmobiusstaging.devinfinitylearn.in/api"
    //1: "https://admin.inmobiusinfinitylearn.com/api"
  },
  phoneBaseUrl: "https://gatewaystaging.devinfinitylearn.in/api",
  otpBaseUrl: "https://otp.infinitylearn.com/api",
  apiVersion: "v1",
  headers: {
    ["product-id"]: "1",
    ["tenant-id"]: "1"
  },
  router: {
    1: "school",
    2: "user",
    3: "teacher"
  },
  countryApiKey: "a1JiWnJrVVp3YmxheTdybjFtbDVId2xjOU1UYUZ4dUZHMVNBMk5pZA=="
});
