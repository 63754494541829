import { useContext, useEffect, useState } from "react";
import DynamicForm from "../../../components/forms/DynamicForm";
import { moduleConfig } from "../../../config";
// import { getGrades, getProducts } from "../../../utils";
import { TableConfig } from "../../table/TableConfig";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";
import { CONFIG_VARS } from "../../../config/globleConfig";
const options = [
    { label: "Inmobius", value: "IM" },
    { label: "Infinity Learn", value: "IL" },
];
const TenantsWrapper = (props) => {
    const { onSubmit, closeModal, data, ModalTitle, dta, setData } = props;
    const [fields, setFields] = useState(moduleConfig.tenants.fields);
    const [formData, setFormData] = useState({ ...data });
    const [loader, setLoader] = useState(false);

    const { products, grades, access } = useContext(CommonApiDataContext);

    useEffect(() => {
        // const accessToken = localStorage.getItem("accessToken");
        const getAllGradesAndProducts = async () => {
            setLoader(true);
            try {
                // const grades = await getGrades(accessToken);
                // const products = await getProducts(1, 100);

                // const updatedGrades = grades.map((e) => ({ label: e.grade_name, value: e.grade_id }));
                // const updatedProducts = products?.products.map((e) => ({ label: e.product_name, value: e.product_id }));

                const filteredProducts = products?.filter((product) => data?.products?.includes(product?.label));
                const filteredGrades = grades?.filter((grade) => data?.grades?.includes(grade?.label));
                const filteredSource = options?.filter((source) => source?.label === data?.source)[0];

                data &&
                    setFormData((prev) => ({
                        ...prev,
                        products: filteredProducts,
                        grades: filteredGrades,
                        source: filteredSource,
                    }));

                const updatedFields = fields.map((e) => {
                    if (e.options && e.name === "products") {
                        return { ...e, options: getProds()?.filter((e) => e.active) };
                    } else if (e.options && e.name === "grades") {
                        return { ...e, options: grades.filter((e) => e.active) };
                    } else if (e.options && e.name === "source") {
                        return { ...e, options: getSources(e.options) };
                    }
                    return e;
                });

                const reqFields = data ? updatedFields : updatedFields.filter((e) => e.showInCreate && e);

                if (access && access.includes("inmobius")) {
                    // for Inmobus Agents grade selection not required in tenant create and update
                    setFields(reqFields.filter((e) => e.name !== "grades"));
                } else {
                    setFields(reqFields);
                }
                setLoader(false);
            } catch (error) {
                setLoader(false);
            }
        };

        getAllGradesAndProducts();
    }, []);

    /*@TODO: need remove in final stage */
    const getProds = () => {
        if (access && access.includes("inmobius")) {
            return products.filter((p) => p.value === CONFIG_VARS.inmobiusProdId);
        } else if (access && access === "learnSuperAdmin") {
            return products.filter((p) => CONFIG_VARS.learnSuperAdminProds.includes(Number(p.value)));
        } else if (access && access === "ILSchoolsSuperAdmin") {
            return products.filter((p) => CONFIG_VARS.ILSchoolsSuperAdminProds.includes(Number(p.value)));
        } else {
            return products;
        }
    };

    /*@TODO: need remove in final stage */
    const getSources = (list) => {
        if (access && access.includes("inmobius")) {
            return list.filter((e) => e.value === "IM");
        } else {
            return list;
        }
    };

    return (
        <DynamicForm
            fields={fields}
            onSubmit={onSubmit}
            closeModal={closeModal}
            ModalTitle={ModalTitle}
            data={data && formData}
            loader={loader}
            actions={moduleConfig?.tenants?.actions}
            dta={dta}
            setData={setData}
            navId={TableConfig.tables[0].items[0].id}
        />
    );
};

export default TenantsWrapper;
